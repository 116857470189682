import React from 'react';

import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  startOfMonth,
  startOfQuarter,
  subMonths,
  subSeconds,
  startOfWeek,
  endOfWeek,
  addMonths,
  addQuarters,
  addWeeks,
  startOfYear,
  endOfYear,
  startOfDay,
  subDays,
  subWeeks,
  subYears,
} from 'date-fns';
import { themeColors } from 'themes/clients/baseTheme';

import {
  Advice,
  BulletList,
  Continue,
  ManagerRabbit,
  Play,
  Praise,
  Public,
  ReceiverManager,
} from 'assets/assetsV1/iconComponents';
import { Person } from 'assets/assetsV1/iconComponents/Person';

import theme from './theme';

export const supportEmail = 'support@mesh.ai';
export const contactEmail = 'contactus@mesh.ai';

export const cookieKeys = {
  employeeDetails: 'employeeDetails',
};

export const localStorageKeys = {
  isOnboardingComplete: 'isOnboardingComplete',
  selectedTeamInMeshList: 'selectedTeamInMeshList',
  selectedTaskType: 'selectedTaskType',
  selectedTasksListViewType: 'selectedTasksListViewType',
  loginType: 'loginType', // how user logged in, to be used in onboarding to decide whether to show change password form or not
  selectedMeshListTab: 'selectedMeshListTab',
  teamPageGoalsFilters: 'teamPageGoalsFilters',
  teamPageGoalsTimeBounds: 'teamPageGoalsTimeBounds',
  teamPageTasksFilters: 'teamPageTasksFilters',
  tagPageGoalsFilters: 'tagPageGoalsFilters',
  tagPageTasksFilters: 'tagPageTasksFilters',
  teamPageGoalsStatsFilters: 'teamPageGoalsStatsFilters',
  teamPageTasksStatsFilters: 'teamPageTasksStatsFilters',
  teamPageGoalsViewType: 'teamPageGoalsViewType',
  teamPageTasksViewType: 'teamPageTasksViewType',
  teamPageObjective: 'teamPageObjective',
  analyticsGoalFilters: 'analyticsGoalFilters',
  analyticsOneOnOneFilters: 'analyticsOneOnOneFilters',
  analyticsFeedbackFilters: 'analyticsFeedbackFilters',
  analyticsLevelUpFilters: 'analyticsLevelUpFilters',
  analyticsEngagementFilters: 'analyticsEngagementFilters',
  analyticsGoalsCSVDownloads: 'analyticsGoalsCSVDownloads',
  analyticsEngagementCSVDownloads: 'analyticsEngagementCSVDownloads',
  analyticsOneOnOneCSVDownloads: 'analyticsOneOnOneCSVDownloads',
  analyticsFeedbackCSVDownloads: 'analyticsFeedbackCSVDownloads',
  reviewTeamStatusSelectedCycle: 'reviewTeamStatusSelectedCycle',
  analyticsGoalTableFilters: 'analyticsGoalTableFilters',
  analyticsOneOnOneTableFilters: 'analyticsOneOnOneTableFilters',
  analyticsEngagementTableFilters: 'analyticsEngagementTableFilters',
  analyticsFeedbackTableFilters: 'analyticsFeedbackTableFilters',
  analyticsLevelUpTableFilters: 'analyticsLevelUpTableFilters',
  adminEmployeesCSVDownloads: 'adminEmployeesCSVDownloads',
  rabbitGoalsInsightsTableRowsPerPage: 'rabbitGoalsInsightsTableRowsPerPage',
  rabbitGoalsAuditTableRowsPerPage: 'rabbitGoalsAuditTableRowsPerPage',
  rabbitGoalsListTableRowsPerPage: 'rabbitGoalsListTableRowsPerPage',
  reviewTablesCSVDownloads: 'reviewTablesCSVDownloads',
  reviewTablesInsightsCSVDownloads: 'reviewTablesInsightsCSVDownloads',
  reviewTablesManageCSVDownloads: 'reviewTablesManageCSVDownloads',
  rabbit1on1GroupByIndividualTableRowsPerPage: 'rabbit1on1GroupByIndividualTableRowsPerPage',
  rabbit1on1GroupByManagerTableRowsPerPage: 'rabbit1on1GroupByManagerTableRowsPerPage',
  rabbitInitiativeTableRowsPerPage: 'rabbitInitiativeTableRowsPerPage',
  rabbitInitiativeAnalyticsFilters: 'rabbitInitiativeAnalyticsFilters',
  rabbitEngagementTableSurveys: 'rabbitEngagementTableSurveys',
  lastNotifiedReviewTime: 'lastNotifiedReviewTime',
  demoUserEmail: 'demoUserEmail',
  /* download okr insights keys start */
  individualInsightsCSVDownload: 'individualInsightsCSVDownload',
  objectiveInsightsCSVDownload: 'objectiveInsightsCSVDownload',
  /* download okr insights keys end */
};

export const PROGRESS_COMPARISON_TYPES = {
  peer: 'MY_PEERS',
  team: 'MY_TEAM',
  organization: 'ORGANIZATION',
};

export const PROGRESS_COMPARISON_DROPDOWN_DATA = [
  {
    name: 'My Peers',
    value: PROGRESS_COMPARISON_TYPES.peer,
  },
  {
    name: 'My Team',
    value: PROGRESS_COMPARISON_TYPES.team,
  },
  {
    name: 'Organization',
    value: PROGRESS_COMPARISON_TYPES.organization,
  },
];

export const SEARCH_TAG_TYPES = {
  team: 'TEAM',
  employee: 'EMPLOYEE_NAME',
  tag: 'ORGTAG',
  location: 'LOCATION',
  goal: 'GOAL',
  goalTag: 'GOAL_TAG',
  taskTag: 'MESH_TASK_TAG',
  subObjectiveTag: 'SUB_OBJECTIVE_TAG',
  objectiveTimeBound: 'OBJECTIVE_TIME_BOUND',
};

export const routes = {
  surveyDetail: '/survey/:surveyId',
  meshStore: '/employee/:employeeId/meshStore/:summaryType',
  strengthDetail: '/employee/:employeeId/strengths/:strengthId',
  driverDetail: '/employee/:employeeId/drivers/:driverId',
  deliverableDetail: '/employee/:employeeId/deliverables/:deliverableId',
  discoverDetail: '/employee/:loggedInEmployeeId/discover/:employeeId',
  meshin: '/meshin',
  meshup: '/meshup',
  profile: '/employee/:employeeId',
  home: '/',
  feedDetail: '/feed/:feedId',
  managers: {
    insights: '/managers/insights/deliver',
    lifecycle: '/managers/lifecycle/hire',
    team: '/managers/team/:teamId',
  },
  login: '/login',
  loginWithRegion: '/login/:region',
  nonAuthCharts: {
    nonAuthEmailChart: '/nonAuthEmailChart',
  },
  staticReviewReportESignSuccess: '/eSignSuccess',
  logout: '/logout',
  msTeams: {
    index: '/msteams',
    Final: '/msteams/final',
    Login: '/msteams/login',
    Goals: '/msteams/goals',
    Tasks: '/msteams/tasks',
    Feedback: '/msteams/feedback',
    TeamHub: '/msTeamsTeamHub',
    Search: '/msTeamsSearch',
    TabConfig: '/msTeamsTabConfig',
    ConnectorConfig: '/msTeamsConnectorConfig',
    msTeamsPerformanceReview: {
      index: '/msTeamsReview',
      forms: '/msTeamsReview/:reviewId/employee/:employeeId',
    },
  },
  salesforce: {
    callback: '/sf-callback',
  },
  gmail: '/gmailplugin',
  outlook: {
    home: '/outlook',
    login: '/outlook/login',
  },
  selectOrganization: '/selectOrganization',
  emailRegister: '/signup',
  validateEmail: '/emailVerification',
  forgotPassword: '/forgotPassword',
  forgotPasswordConfirmation: '/forgotPasswordConfirmation',
  selectDepartment: '/selectDepartment',
  selectTeam: '/selectTeam',
  invite: '/invite',
  profileSetup: '/profileSetup',
  admin: '/settings',
  moduleSettings: '/moduleSettings',
  teamSettings: '/settings/teams/:teamId',
  // settings: '/settings/myProfile',
  referral: '/settings/referral',
  internalTools: {
    campaign: '/settings/internalTools/campaign',
  },
  settings: {
    index: '/settings',
    myProfile: '/settings/myProfile',
    referral: '/settings/referral',
    knowledgeHub: '/settings/knowledge-hub',
    team: '/settings/teams/:teamId',
    // not valid now, but keeping for redirect service
    meshTags: '/settings/meshTags',
    objectives: '/settings/objectives',
    // not valid now, but keeping for redirect service
    objectiveSettings: '/settings/objectiveSettings',
    competencies: '/settings/competencies',
    company: '/settings/company',
    modules: '/settings/modules',
    applause: '/settings/applause',
    employees: '/settings/employees',
    functionAndGrade: '/settings/function-grade',
    notifications: '/settings/notifications',
    myNotifications: '/settings/my-notifications', // need to get clarity on the route path
    customAccess: '/settings/customAccess',
    newUX: '/settings/newUX',
    // plugins routes
    plugins: {
      slack: '/settings/plugins/slack',
      msTeams: '/settings/plugins/msTeams',
      salesforce: '/settings/plugins/salesforce',
      jira: '/settings/plugins/jira',
      darwinbox: '/settings/plugins/darwinbox',
      hris: '/settings/plugins/hris',
    },
    // helpcenter routes
    userguide: '/settings/userguide',
    troubleshoot: '/settings/troubleshoot',
    checkIn: {
      settings: '/settings/checkIn/settings',
      questions: '/settings/checkIn/questions',
      preferences: '/settings/checkIn/preferences',
      templates: '/settings/checkIn/templates',
      calendar: '/settings/checkIn/calendar',
      verifyCalendar: '/calendar/verify/',
    },
    reportBug: '/settings/reportBug',
    // reviews routes
    review: {
      reviewProcess: '/settings/reviews/reviewCycles',
      reviewCycle: '/settings/reviews/reviewCycle/:formId/:step',
      questions: '/settings/reviews/questions',
      // questionDetails: '/settings/reviews/questionDetails/:questionId',
      reviewSettings: '/settings/reviews/calibrationSettings',
      reviewTemplate: {
        list: '/settings/reviews/template',
      },
    },
    engagement: {
      pulseSurvey: '/settings/engagement/pulse-survey',
      questions: '/settings/engagement/questions',
      pulseKickstart: '/settings/engagement/pulseKickstart/:formId/:step',
      drivers: '/settings/engagement/drivers',

      templates: '/settings/engagement/templates',
    },
    growth: {
      index: '/settings/level-up',
      jobFunctionTable: '/settings/level-up/:jobFunctionId',
    },
  },
  // tagDetail: '/tag/:tagId',
  teamSummary: '/teamSummary',
  // mobile only
  tasksSummary: '/tasksSummary',
  searchResults: '/search',
  performanceReview: {
    index: '/review',
    audit: '/review/audit',
    performancePreview: '/review/performancePreview',
    manage: '/review/manage',
    talentPlanning: '/review/calibrationView',
    insights: '/review/insights',
    // teamStatus: '/review/teamStatus',
    forms: '/review/:reviewId/employee/:employeeId',
    formsOld: '/review/:reviewId/employee/:employeeId/v0',
    result: '/review/result/:reviewId/employee/:employeeId',
    resultChartOnly: '/review/result/chart/:reviewId/employee/:employeeId',
  },
  analytics: {
    index: '/analytics',
    goals: '/analytics/goals',
    oneOnOne: '/analytics/oneOnOne',
    feedback: '/analytics/feedback',
    levelUp: '/analytics/develop',
    continuousFeedback: '/analytics/feedback',
  },
  engagement: {
    index: '/engagement',
    surveyQuestionnaire: '/engagement/survey/:surveyId',
    pastSurvey: '/engagement/submittedSurvey/:surveyId',
    manage: '/engagement/insights',
  },
  initiative: {
    index: '/initiative',
    audit: '/initiative/audit',
    cards: '/initiative/cards',
  },
  template: {
    review: {
      index: '/template/review/:templateId',
      preview: '/template/review/:templateId/preview',
    },
    checkIn: {
      index: '/template/checkin/:templateId',
    },
    engagement: {
      index: '/template/engagement/:templateId',
      preview: '/template/engagement/:templateId/preview',
    },
  },
  oneOnOne: {
    index: '/oneOnOne',
    instance: '/oneOnOne/:instanceId',
    legacyInstance: '/oneOnOne/legacy/id/:instanceId',
    masterPageRabbit: '/oneOnOne/:masterId',
    instanceRabbit: '/oneOnOne/:masterId/:instanceId',
    timeline: '/oneOnOne/:instanceId/timeline',
    audit: '/oneOnOne/audit',
    insights: '/oneOnOne/insights',
  },
  levelUp: {
    index: '/develop',
    myTeam: '/develop/myTeam',
    myTeamInsights: '/analytics/develop/myTeam',
  },
  continuousFeedback: {
    index: '/feedback',
    myPraisesAndAdvices: '/feedback/myPraisesAndAdvices',
  },
  goals: {
    index: '/goals',
    audit: '/goals/audit',
    list: '/goals/list',
    card: '/goals/card',
    tree: '/goals/tree',
    insights: '/goals/insights',
    archived: '/goals/archived',
  },
  personaInsights: {
    index: '/personaInsights',
  },
  embeddedAnalytics: {
    index: '/embeddedAnalytics/:id',
    connectIframe: '/connect-visier',
  },
};

// Todo: mixpanel this will be removed after all references are removed
export const routesForTracking = {
  login: { path: '/login', pageName: 'Login' },
  searchResults: { path: '/search', pageName: 'searchResults' },
  profile: { path: '/employee/:employeeId', pageName: 'UserProfile' },
  home: { path: '/', pageName: 'HomePage' },
  post: { path: '/feed/:feedId', pageName: 'Post' },
  team: { path: '/managers/team/:teamId', pageName: 'TeamPage' },
  // -------- signup routes --------------
  emailRegister: { path: '/signup', pageName: 'EmailCapture' },
  validateEmail: { path: '/emailVerification', pageName: 'EmailValidation' },
  forgotPassword: { path: '/forgotPassword', pageName: 'ForgotPassword' },
  forgotPasswordConfirmation: {
    path: '/forgotPasswordConfirmation',
    pageName: 'ForgotPasswordConfirmation',
  },
  selectDepartment: { path: '/selectDepartment', pageName: 'DeptSelection' },
  selectTeam: { path: '/selectTeam', pageName: 'TeamName' },
  invite: { path: '/invite', pageName: 'TeamInvites' },
  // -------- signup routes end -------------
  teamSettings: {
    path: '/settings/teams/:teamId',
    pageName: 'teamSettings',
  },
  // settings: '/settings/myProfile',
  referral: { path: '/settings/referral', pageName: 'referral' },
  settings: { path: '/settings', pageName: 'Settings' },
  myProfileSettings: {
    path: '/settings/myProfile',
    subPageName: 'MyProfileSettings',
    pageName: 'Settings',
  },
  referralSettings: {
    path: '/settings/referral',
    subPageName: 'ReferralSettings',
    pageName: 'Settings',
  },
  tagDetail: { path: '/tag/:tagId', pageName: 'HashTagPage' },

  // new routes.
  // Analytics routes start here
  analyticsGoals: {
    path: routes.analytics.goals,
    pageName: 'GoalAnalytics',
  },
  analyticsOneOnOne: {
    path: routes.analytics.oneOnOne,
    pageName: 'OneOnOneAnalytics',
  },
  analyticsFeedback: {
    path: routes.analytics.feedback,
    pageName: 'FeedbackAnalytics',
  },
  analyticsDevelop: {
    path: routes.analytics.levelUp,
    pageName: 'DevelopAnalytics',
  },

  // settings Routes start here.
  // meshTagsSettings: {
  //   path: routes.settings.meshTags,
  //   pageName: 'MeshTagsSettings'
  // },
  objectivesSettings: {
    path: routes.settings.objectives,
    pageName: 'ObjectivesSettings',
  },
  competenciesSettings: {
    path: routes.settings.competencies,
    pageName: 'CompetenciesSettings',
  },
  companySettings: {
    path: routes.settings.company,
    pageName: 'CompanySettings',
  },
  applauseSettings: {
    path: routes.settings.applause,
    pageName: 'ApplauseSettings',
  },
  employeesSettings: {
    path: routes.settings.employees,
    pageName: 'EmployeesSettings',
  },
  slackPluginSettings: {
    path: routes.settings.plugins.slack,
    pageName: 'SlackPluginSettings',
  },
  msTeamsPluginSettings: {
    path: routes.settings.plugins.msTeams,
    pageName: 'MsTeamsPluginSettings',
  },
  salesforcePluginSettings: {
    path: routes.settings.plugins.salesforce,
    pageName: 'salesforcePluginSettings',
  },
  jiraPluginSettings: {
    path: routes.settings.plugins.jira,
    pageName: 'JiraPluginSettings',
  },
  darwinPluginSettings: {
    path: routes.settings.plugins.darwinbox,
    pageName: 'DarwinPluginSettings',
  },
  userGuideSettings: {
    path: routes.settings.userguide,
    pageName: 'UserGuideSettings',
  },
  troubleshootSettings: {
    path: routes.settings.troubleshootSettings,
    pageName: 'TroubleshootSettings',
  },
  // checkinSettings: {
  //   path: routes.settings.checkIn.settings,
  //   pageName: 'OneOnOneSettings'
  // },
  // checkinQuestionsSettings: {
  //   path: routes.settings.checkIn.questions,
  //   pageName: 'OneOnOneQuestionsSettings'
  // },
  reportBugSettings: {
    path: routes.settings.reportBug,
    pageName: 'ReportBugSettings',
  },
  reviewProcessSettings: {
    path: routes.settings.reviewProcess,
    pageName: 'ReviewProcessSettings',
  },
  reviewCycleSettings: {
    path: routes.settings.reviewCycle,
    pageName: 'ReviewCycleSettings',
  },
  reviewQuestionsSettings: {
    path: routes.settings.questions,
    pageName: 'ReviewQuestionsSettings',
  },
  // engagementSettings: {
  //   path: routes.settings.engagement.pulseSurvey,
  //   pageName: 'PulseSurveySettings'
  // },
  engagementKickStartSettings: {
    path: routes.settings.engagement.pulseKickstart,
    pageName: 'PulseKickStartSettings',
  },
  // engagementQuestionsSettings: {
  //   path: routes.settings.engagement.questions,
  //   pageName: 'PulseQuestionsSettings'
  // },

  engagementLanding: { path: '/engagement', pageName: 'Engagement' },
  engagementQuestionnaire: {
    path: routes.engagement.surveyQuestionnaire,
    pageName: 'PulseSurveyQuestionnaire',
  },
  engagementPastSurvey: {
    path: routes.engagement.pastSurvey,
    pageName: 'PulsePastSurvey',
  },

  reviewLandingPage: { path: routes.performanceReview.index, pageName: 'Review' },
  // reviewTeamStatusPage: { path: routes.performanceReview.teamStatus, pageName: 'ReviewTeamStatus' },
  reviewForm: { path: routes.performanceReview.forms, pageName: 'ReviewForms' },

  oneOnOneLandingPage: {
    path: routes.oneOnOne.index,
    pageName: 'OneOnOne',
  },

  // oneOnOneInstancePage: {
  //   path: routes.oneOnOne.instance,
  //   pageName: '1:1 instance page'
  // },

  employeesOneOnOneList: {
    path: routes.oneOnOne.timeline,
    pageName: 'TimelinePage',
  },

  developLandingPage: {
    path: routes.levelUp.index,
    pageName: 'DevelopLandingPage',
  },
  developTeamPage: {
    path: routes.levelUp.myTeam,
    pageName: 'DevelopMyTeamPage',
  },

  // rabbit routes for tracking ************************************************

  // reviews
  myReviewsExplorePage: {
    path: routes.performanceReview.audit,
    pageName: 'MyReviews',
    type: 'Explore',
  },
  myReviewsPerformancePreviewPage: {
    path: routes.performanceReview.performancePreview,
    pageName: 'MyReviews',
    type: 'PerformancePreview',
  },
  reviewsCohortExplorePage: {
    path: routes.performanceReview.manage,
    pageName: 'ReviewsCohortExplorePage',
    type: 'Explore',
  },
  reviewsCohortInsightsPage: {
    path: routes.performanceReview.insights,
    pageName: 'ReviewsCohortInsightsPage',
    type: 'Analytics',
  },
};

export const MODULE_KEYS = {
  GOAL: 'GOAL',
  TASK: 'TASK',
  REVIEW: 'REVIEW',
  ENGAGEMENT: 'ENGAGEMENT',
  ONE_ON_ONE: 'ONE_ON_ONE',
  FEEDBACK: 'FEEDBACK',
  LEVEL_UP: 'LEVEL_UP',
};

export const MODULE_KEYS1 = {
  GOAL: 'goal',
  INITIATIVE: 'initiative',
  REVIEW: 'status',
  ENGAGEMENT: 'engagement',
  ONE_ON_ONE: 'oneOnOne',
  DEVELOP: 'levelUp',
  FEEDBACK: 'competency',
  OLD_INSIGHTS_PAGE: 'oldInsightsPage',
  MODULE_SETTINGS: 'moduleSettings',
  DUMMY_INITIATIVE: 'dummyInitiative',
  CONTINUOUS_FEEDBACK: 'continuousFeedback',
  PERSONA_INSIGHTS: 'personaInsight',
};

export const SIDE_NAV_MATCH_ROUTES = [
  // goal module
  {
    module: MODULE_KEYS1.GOAL,
    routes: [
      routes.goals.index,
      routes.goals.audit,
      routes.goals.list,
      routes.goals.card,
      routes.goals.tree,
      routes.goals.insights,
      routes.analytics.goals,
      routes.goals.archived,
      routes.embeddedAnalytics.index.replace(':id', 'goals'),
    ],
  },

  // initiatives module
  {
    module: MODULE_KEYS1.INITIATIVE,
    routes: [routes.initiative.index, routes.initiative.audit, routes.initiative.card],
  },

  // 1:1
  {
    module: MODULE_KEYS1.ONE_ON_ONE,
    routes: [
      routes.oneOnOne.index,
      routes.oneOnOne.audit,
      routes.oneOnOne.insights,
      routes.analytics.oneOnOne,
      routes.settings.checkIn.questions,
      routes.settings.checkIn.templates,
      routes.settings.checkIn.preferences,
      routes.settings.checkIn.settings,
      routes.oneOnOne.instanceRabbit,
      routes.embeddedAnalytics.index.replace(':id', 'oneOnOne'),
    ],
  },

  // develop
  {
    module: MODULE_KEYS1.DEVELOP,
    routes: [routes.levelUp.index, routes.analytics.levelUp, routes.levelUp.myTeam, routes.levelUp.myTeamInsights],
  },
  {
    module: MODULE_KEYS1.CONTINUOUS_FEEDBACK,
    routes: [
      routes.continuousFeedback.index,
      routes.continuousFeedback.myPraisesAndAdvices,
      routes.analytics.continuousFeedback,
    ],
  },
  // review
  {
    module: MODULE_KEYS1.REVIEW,
    routes: [
      routes.performanceReview.index,
      routes.performanceReview.audit,
      routes.performanceReview.performancePreview,
      routes.performanceReview.manage,
      routes.performanceReview.talentPlanning,
      routes.performanceReview.insights,
      // routes.performanceReview.teamStatus,
      routes.settings.review.reviewCycle,
      routes.performanceReview.forms,
      routes.performanceReview.result,
      routes.embeddedAnalytics.index.replace(':id', 'reviews'),
    ],
  },

  // engagement
  {
    module: MODULE_KEYS1.ENGAGEMENT,
    routes: [
      routes.engagement.index,
      routes.analytics.engagement,
      routes.engagement.manage,
      routes.engagement.insights,
    ],
  },

  { module: MODULE_KEYS1.MODULE_SETTINGS, routes: [routes.moduleSettings] },

  // persona insights
  {
    module: MODULE_KEYS1.PERSONA_INSIGHTS,
    routes: [routes.personaInsights.index],
  },
];

// export const OLD_INSIGHT_PAGE_ROUTES = [
//   { name: 'Goals', route: routes.analytics.goals ,featureKey: FEATURE_KEYS.GOAL_ANALYTICS},
//   { name: '1:1 Meetings', route: routes.analytics.oneOnOne ,featureKey: FEATURE_KEYS.ONE_ON_ONE_ANALYTICS},
//   { name: 'Review', route: routes.analytics.review ,featureKey: FEATURE_KEYS.RE},
//   { name: 'Engagement', route: routes.analytics.engagement ,featureKey: FEATURE_KEYS.GOAL},
//   { name: 'Develop', route: routes.analytics.levelUp ,featureKey: FEATURE_KEYS.GOAL},
//   { name: 'Feedback', route: routes.analytics.feedback ,featureKey: FEATURE_KEYS.GOAL}
// ];

export const COHORT_CREATION_PAGE_TYPE = {
  [MODULE_KEYS1.GOAL]: 'GOAL',
  [MODULE_KEYS1.INITIATIVE]: 'INITIATIVE',
  [MODULE_KEYS1.ONE_ON_ONE]: 'ONE_ON_ONE',
  [MODULE_KEYS1.ENGAGEMENT]: 'ENGAGEMENT',
  [MODULE_KEYS1.FEEDBACK]: 'CORE_COMPETENCY',
  [MODULE_KEYS1.REVIEW]: 'REVIEW',
  [MODULE_KEYS1.CONTINUOUS_FEEDBACK]: 'CONTINUOUS_FEEDBACK',
  [MODULE_KEYS1.PERSONA_INSIGHTS]: 'PERSONA_INSIGHTS',
  [MODULE_KEYS1.DEVELOP]: 'LEVEL_UP',
  STATUS: 'STATUS',
  CALIBRATION: 'CALIBRATION',
};

export const SETTINGS_ROUTE_FOR_HEADER = {
  goals: [
    routes.goals.index,
    // routes.settings.objectiveSettings
  ],
  initiatives: [
    routes.initiative.index,
    // routes.settings.meshTags
  ],
  oneOnOne: [
    routes.oneOnOne.index,
    // routes.settings.checkIn.questions,
    // routes.settings.checkIn.templates,
    // routes.settings.checkIn.preferences,
    // routes.settings.checkIn.settings,
    routes.analytics.oneOnOne,
  ],
  review: [
    routes.performanceReview.index,
    // routes.settings.review.questions,
    // routes.settings.review.reviewTemplate.list,
    // routes.settings.review.reviewProcess,
    // routes.settings.review.reviewSettings,
  ],
  engagement: [
    routes.engagement.index,
    // routes.settings.engagement.drivers,
    // routes.settings.engagement.questions,
    // routes.settings.engagement.templates,
    // routes.settings.engagement.pulseSurvey,
    routes.analytics.engagement,
  ],
  levelUp: [routes.levelUp.index, routes.levelUp.myTeam, routes.analytics.levelUp],
  continuousFeedback: [
    routes.continuousFeedback.index,
    routes.continuousFeedback.myPraisesAndAdvices,
    routes.analytics.continuousFeedback,
  ],
  personaInsights: [routes.personaInsights.index],
  embeddedAnalytics: [routes.embeddedAnalytics.index],
};

export const SENTIMENTS = {
  constructive: 'CONSTRUCTIVE',
  appreciative: 'APPRECIATIVE',
  neutral: 'NEUTRAL',
};

export const POST_VISIBILITY_TYPES = {
  global: {
    name: 'GLOBAL',
    displayName: 'Public',
  },
  private: {
    name: 'PRIVATE',
    displayName: 'Private',
  },
};

export const TEAM_DURATIONS_OBJECT = {
  yesterday: 'YESTERDAY',
  lastWeek: 'LASTWEEK',
  last2Weeks: 'LAST2WEEKS',
  lastMonth: 'LASTMONTH',
};

export const TEAM_DURATION_VALUES = [
  { name: 'Since Yesterday', value: 'YESTERDAY' },
  { name: 'Since Last Week', value: 'LASTWEEK' },
  { name: 'Since Last 2 Weeks', value: 'LAST2WEEKS' },
  { name: 'Since Last Month', value: 'LASTMONTH' },
];

export const SEARCH_WIDGET_TYPES = {
  search: 'SEARCH',
  post: 'POST',
  comment: 'COMMENT',
  task: 'TASK',
  goal: 'GOAL',
  primaryPod: 'PRIMARY_POD',
  grade: 'GRADE',
  function: 'FUNCTION',
};

export const TRANSACTION_SUMMARY_TYPES = { received: 'RECEIVED', shared: 'SHARED' };

export const TASK_TYPES = { active: 'ACTIVE', completed: 'COMPLETED' };

export const SIZES_KEYS_ARRAY = ['smallest', 'smaller', 'small', 'smallMedium', 'medium', 'big'];

export const SEARCH_PLACEHOLDER = 'Search for team member';

export const TEAM_TASKS_VIEW_TYPES = { card: 'CARD', list: 'LIST', goalTree: 'TREE' };
export const TEAM_TASKS_VIEW_NAMES = {
  [TEAM_TASKS_VIEW_TYPES.card]: 'Card View',
  [TEAM_TASKS_VIEW_TYPES.list]: 'List View',
  [TEAM_TASKS_VIEW_TYPES.goalTree]: 'Tree View',
};

// ################ placeholders and texts ###################
export const CREATE_GOAL_TASK_PLACEHOLDER = 'Key result to be achieved ...';
export const CREATE_TASK_PLACEHOLDER = 'Will be working on';
export const ADD_POST_PLACEHOLDER = 'What would you like to share?';
export const ADD_TEXT = {
  task: 'Create new task',
  todo: 'Create new to-do',
  goal: 'Create new goal',
};

// Names of common entities of the project
// Making them constant because they kept changing
export const MESH_IN_DISPLAY_TEXT = 'Portfolio';
export const MESH_UP_DISPLAY_TEXT = 'My Impact';
export const MESH_TEAM_DISPLAY_TEXT = 'Team Impact';

// Error messages
export const ERR_MESS_CREATE_COMMENT = 'An error occurred while creating comment';
export const ERROR_MESSAGE = 'Sorry an error occurred while fetching the data';
export const ERROR_MESSAGE_ACTION = 'Sorry an error occurred while performing the action. Please try again later!';
export const CREATE_POST_ERROR = "Sorry couldn't create post, you have not provided correct data!";
export const UPDATE_POST_ERROR = "Sorry couldn't update post";

export const CREATE_POST_SUCCESS = 'Successfully created a public post!';
export const POST_PRAISE_SUCCESS = 'Successfully shared praise with everyone!';
export const POST_FEEDBACK_SUCCESS = 'Successfully shared!';

export const UPDATE_POST_SUCCESS = 'Successfully updated post.';
export const IMAGE_UPLOAD_ERROR = 'An error occurred while uploading the image';
export const ERROR_ADD_APPLAUD_FEED = 'Error applauding the post';
export const ERROR_ADD_LIKE_FEED = 'Error liking the post';
export const ERROR_ADD_APPLAUD_TASK = 'Error applauding the task';
export const ERROR_ADD_LIKE_TASK = 'Error applauding the task';

// ---- Comment -------
export const COMMENT_EDIT_ERROR = 'Sorry an error occurred while updating comment';

// ----------

// Success Messages
export const IMAGE_UPLOAD_SUCCESS = 'Successfully uploaded the image';
export const SUCCESS_ADD_APPLAUD_FEED = 'Successfully applauded the post';
export const SUCCESS_ADD_LIKE_FEED = 'Successfully liked the post';
export const SUCCESS_ADD_APPLAUD_TASK = 'Successfully applauded the task';
export const SUCCESS_ADD_LIKE_TASK = 'Successfully liked the task';

// -------------

// No data component texts
export const MESH_WIN_NO_DATA = 'Currently, you have no trophies!';
export const FEEDBACKS_NO_DATA = 'No feedbacks yet.';
export const DEPARTMENTS = [
  'Biotechnology',
  'Finance',
  'HR',
  'Manufacturing',
  'Marketing',
  // 'Medical equipments',
  'Operations',
  // 'Pharmaceutical',
  'Production',
  'Sales',
  'Strategy',
  'Technology',
  'Others (Design/R&D etc.)',
];

export const ETHNICITIES = [
  'Asian or Pacific Islander',
  'Black or African American',
  'Hispanic or Latino',
  'Multiracial or Biracial',
  'Native American or Alaskan Native',
  'White or Caucasian',
  'An ethnicity not listed here',
];

// need to add support for 'other' genders
export const GENDERS = {
  MALE: {
    key: 'MALE',
    label: 'Male',
    label2: 'Men',
  },
  FEMALE: {
    key: 'FEMALE',
    label: 'Female',
    label2: 'Women',
  },
  OTHER: {
    key: 'OTHER',
    label: 'Other',
    label2: 'Others',
  },
};

export const GOAL_ADMIN = 'GOAL_ADMIN';
export const GOAL_ADMIN_LABEL = 'Goals';

export const ONE_ON_ONE_ADMIN = 'ONE_ON_ONE_ADMIN';
export const ONE_ON_ONE_ADMIN_LABEL = '1:1';

export const REVIEW_ADMIN = 'REVIEW_ADMIN';
export const REVIEW_ADMIN_LABEL = 'Reviews';

export const ENGAGEMENT_ADMIN = 'ENGAGEMENT_ADMIN';
export const ENGAGEMENT_ADMIN_LABEL = 'Engagement';

export const FEEDBACK_ADMIN = 'FEEDBACK_ADMIN';
export const FEEDBACK_ADMIN_LABEL = 'Feedback';

export const PLATFORM_ADMIN = 'PLATFORM_ADMIN';
export const PLATFORM_ADMIN_LABEL = 'Platform';

export const TASK_ADMIN = 'TASK_ADMIN';
export const TASK_ADMIN_LABEL = 'Initiatives';

export const CUSTOM_ADMIN = 'CUSTOM_ADMIN';

export const ADMIN_ROLES_MAP_ORDER = {
  PLATFORM_ADMIN: {
    order: 0,
  },
  GOAL_ADMIN: {
    order: 1,
  },
  ONE_ON_ONE_ADMIN: {
    order: 2,
  },
  REVIEW_ADMIN: {
    order: 3,
  },
  ENGAGEMENT_ADMIN: {
    order: 4,
  },
  FEEDBACK_ADMIN: {
    order: 5,
  },
  TASK_ADMIN: {
    order: 6,
  },
};

export const FEATURE_KEYS = {
  ENGAGEMENT: 'ENGAGEMENT',
  REVIEW: 'REVIEW',
  GOAL: 'GOAL',
  CHECK_IN: 'CHECK_IN',
  TASK: 'TASK',
  FEEDBACK: 'FEEDBACK',
  INITIATIVE: 'INITIATIVE',

  CALIBRATION_ANALYTICS: 'CALIBRATION_ANALYTICS',
  STATUS_ANALYTICS: 'STATUS_ANALYTICS',
  GOAL_OBJECTIVE_ANALYTICS: 'GOAL_OBJECTIVE_ANALYTICS',
  GOAL_ANALYTICS: 'GOAL_ANALYTICS',
  ONE_ON_ONE_ANALYTICS: 'ONE_ON_ONE_ANALYTICS',
  FEEDBACK_ANALYTICS: 'FEEDBACK_ANALYTICS',
  ENGAGEMENT_ANALYTICS: 'ENGAGEMENT_ANALYTICS',

  PODS: 'PODS',
  COHORT_CREATION_ANALYTICS: 'COHORT_CREATION_ANALYTICS',
  TEAM_SUMMARY: 'TEAM_SUMMARY',
  GROWTH: 'GROWTH',

  LEVEL_UP: 'LEVEL_UP',
  OVERVIEW: 'OVERVIEW',
};

export const USER_ROLES = ({ enabledFeatures = [] }) => {
  // returns array of user roles applicable by checking which features are enabled for the org
  const userRoles = [];
  userRoles.push({
    value: PLATFORM_ADMIN,
    label: 'Platform',
  });
  if (enabledFeatures?.includes(FEATURE_KEYS.GOAL)) {
    userRoles.push({
      value: GOAL_ADMIN,
      label: 'Goals',
    });
  }
  if (enabledFeatures?.includes(FEATURE_KEYS.CHECK_IN)) {
    userRoles.push({
      value: ONE_ON_ONE_ADMIN,
      label: '1:1',
    });
  }
  if (enabledFeatures?.includes(FEATURE_KEYS.REVIEW)) {
    userRoles.push({
      value: REVIEW_ADMIN,
      label: 'Reviews',
    });
  }
  if (enabledFeatures?.includes(FEATURE_KEYS.ENGAGEMENT)) {
    userRoles.push({
      value: ENGAGEMENT_ADMIN,
      label: 'Engagement',
    });
  }
  if (enabledFeatures?.includes(FEATURE_KEYS.FEEDBACK)) {
    userRoles.push({
      value: FEEDBACK_ADMIN,
      label: 'Feedback',
    });
  }
  if (enabledFeatures?.includes(FEATURE_KEYS.INITIATIVE)) {
    userRoles.push({
      value: TASK_ADMIN,
      label: 'Initiatives',
    });
  }
  return userRoles;
};

// todo: refactor - move?
export const updateEmployeeFields = [
  'aggregatedValues',
  'createdTS',
  'updatedTS',
  'externalEmployeeId',
  'firstName',
  'lastName',
  'displayName',
  'designation',
  'location',
  'gender',
  'joiningDate',
  'resignationDate',
  'department',
  'tenure',
  'email',
  'country',
  'preferredFirstName',
  'dateOfBirth',
  'managerEmail',
  'userRole',
  'primaryPod',
  'secondaryPods',
  'employeeFunction',
  'grade',
  'ethnicity',
  'jobFunctionId',
  'jobGradeUI',
  'jobGradeNameUI',
  'jobRoleId', // not a good approach, maybe in next project we should use better way of updating user. Right now to add 1 more employee field lot of files have to be updated.
  'employeeAccesses',
  'jobGradeNameUI',
  'preferredName',
  'experienceBeforeJoining',
  'customFields',
];

export const REFERRAL_SHARE_MESSAGE =
  'Mesh has made it super easy for my team to run effective sync ups and co-ordinate tasks at work. Sign up for free with my referral code - ';

export const URL_REGEX =
  // eslint-disable-next-line no-useless-escape
  /(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?/gm;
export const HASHTAG_VALIDATION_REGEX = /^[a-zA-Z0-9\b]+$/;

export const PASSWORD_REGEX =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

export const USER_LOGIN_EVENTS_ACTIONS = {
  confirm: 'CONFIRM',
};

export const SIGN_UP_ROUTES = [
  routes.login,
  routes.selectDepartment,
  routes.selectTeam,
  routes.invite,
  routes.emailRegister,
  routes.validateEmail,
  routes.forgotPassword,
  routes.forgotPasswordConfirmation,
  routes.selectOrganization,
];

// TODO: this implementation is a bit hackier, fix this with regex in v1.
export const GMAIL_ROUTES = [routes.gmail, `${routes.gmail}/`];
export const OUTLOOK_ROUTES = [routes.outlook.home, routes.outlook.login];
export const PASSWORD_HELP_TEXT =
  'Min. 8 characters with at least 1 upper case, lower case, number and special character';

export const SIGNUP_SELECT_DEPARTMENT_HELP_TEXT =
  'This will help us show you helpful examples of how best to use Mesh Now for managing work within your department.';

export const SIGNUP_CREATE_TEAM_HELP_TEXT =
  "What your pod is referred to as or liked to be called. The greatest superhero pod in the world is called 'Avengers'";

export const POST_BEFORE_OPEN_TEXT = 'Share Public Post';

export const POST_BEFORE_OPEN_TEXT_RABBIT = {
  CREATE_POST_PRAISE: 'Share Praise or Create New Post',
  CREATE_POST: 'Create New Post',
  CREATE_POST_OR_ADVICE: 'Share Praise or Feedback',
};

export const ADD_POST_MODAL_TYPES = {
  CREATE_POST: 'CREATE_POST',
  GIVE_FEEDBACK: 'GIVE_FEEDBACK',
  GIVE_KUDOS: 'GIVE_KUDOS',
};

export const POST_MODAL_PLACEHOLDER_TEXTS = {
  CREATE_POST: 'Create a post that is shared on everyone feed (Use @ to tag people and # to include competencies)',
  CREATE_POST_WITHOUT_COMPETENCY: 'Create a post that is shared on everyone feed (Use @ to tag people)',
  GIVE_KUDOS: 'Praise your coworker on something they did well',
  GIVE_FEEDBACK: 'Use @ to tag people and # to include a competency',
};

export const POST_MODAL_PLACEHOLDER_TEXTS_RABBIT = {
  CREATE_POST: 'Create a post that is shared on everyone feed (Use @ to tag people and # to include competencies)',
  CREATE_POST_WITHOUT_COMPETENCY: 'Create a post that is shared on everyone feed (Use @ to tag people)',
  GIVE_KUDOS: 'Praise your coworker on something they did well',
  GIVE_FEEDBACK: 'Use @ to tag people and # to include a competency',
};

export const POST_BEFORE_OPEN_TEXT_MOBILE = 'Create a post';

export const EMPTY_GROUP_TEXT = 'Unplanned Deliverables';

export const POST_MODAL_COMPETENCY_NOT_APPLICABLE_ERROR = 'Added competency is not applicable to these individuals.';

export const FILTERS = [
  {
    value: 'NAME',
    label: 'Employee Name',
    initial: 'N',
    color: 'rgba(0, 142, 240, 1)', // #008EF0
    bgColor: 'rgba(0, 142, 240, 0.15)',
  },
  {
    value: 'TEAM',
    label: 'Team',
    initial: 'T',
    color: 'rgba(255, 194, 0, 1)',
    bgColor: 'rgba(255, 194, 0, 0.15)',
  },
  {
    value: 'TAGS',
    label: 'Tag',
    initial: 'T',
    color: 'rgba(112, 116, 213, 1)', // #7074D5
    bgColor: 'rgba(112, 116, 213, 0.15)',
  },
  {
    value: 'GOALS',
    label: 'Objective',
    initial: 'O',
    color: 'rgba(255, 150, 74, 1)', // #ff964a
    bgColor: 'rgba(255, 150, 74, 0.15)',
  },
  {
    value: 'STATUS',
    label: 'Status',
    initial: 'S',
    color: 'rgba(247, 86, 64, 1)', // #F75640
    bgColor: 'rgba(247, 86, 64, 0.15)',
  },
  {
    value: 'ACTIVE_CONTENT',
    label: 'Due Date',
    initial: 'D',
    color: 'rgba(80, 183, 171, 1)', // #50B7AB
    bgColor: 'rgba(80, 183, 171, 0.15)',
  },
  {
    value: 'DURATION',
    label: 'Duration',
    initial: 'T',
    color: 'rgba(255, 194, 0, 1)',
    bgColor: 'rgba(255, 194, 0, 0.15)',
  },
  {
    value: 'PRIMARY_POD',
    label: 'Primary Pod',
    initial: 'P',
    color: 'rgba(255, 194, 0, 1)', // same as duration
    bgColor: 'rgba(255, 194, 0, 0.15)', //  same as duration
  },
  {
    value: 'TASK_TYPE',
    label: 'Task Type',
    initial: 'T',
    color: 'rgba(255, 194, 0, 1)', // same as duration
    bgColor: 'rgba(255, 194, 0, 0.15)', //  same as duration
  },
];

export const GOAL_PROGRESSES = [
  {
    label: 'On-Track',
    value: 'ON_TRACK',
    color: 'green',
    key: 'onTrackProgress',
  },
  {
    label: 'Off-Track',
    value: 'OFF_TRACK',
    color: 'orange',
    key: 'offTrackProgress',
  },
  {
    label: 'At-Risk',
    value: 'AT_RISK',
    color: 'red',
    key: 'atRiskProgress',
  },
  {
    label: 'Closed',
    value: 'CLOSED',
    color: 'primary',
    key: 'closedProgress',
  },
];

export const GOAL_PROGRESS_VALUE_TO_OBJECT_MAP = GOAL_PROGRESSES.reduce(
  (prev, curr) => ({ ...prev, [curr.value]: curr }),
  {}
);

export const STATS_DATA = {
  TASK: [
    {
      label: 'Completed',
      value: 'COMPLETED',
      color: 'green',
      key: 'completed', // this key is same as the one returned by api.
    },
    {
      label: 'Ongoing',
      value: 'ONGOING',
      color: 'orange',
      key: 'ongoing',
    },
    {
      label: 'Delayed',
      value: 'DELAYED',
      color: 'red',
      key: 'delayed',
    },
    {
      label: 'Unplanned',
      value: 'UNPLANNED',
      color: 'primary',
      key: 'unplanned',
    },
  ],
  GOAL: GOAL_PROGRESSES,
};

export const DATE_OPERATORS = [
  {
    label: 'Before',
    value: 'LESS_THAN',
  },
  { label: 'After', value: 'GREATER_THAN' },
  { label: 'Equals', value: 'EQUALS' },
];
export const PAGE_TYPES = {
  profile: 'PROFILE_PAGE',
  home: 'HOME_PAGE',
  review: 'REVIEW',
  oneOnOne: 'ONE_ON_ONE',
};

export const REACTIONS = { applause: 'APPLAUSE', like: 'LIKE', comment: 'COMMENT' };

export const PREFERRED_TEAM_INFO_TEXT =
  'Preferred will always appear on top of the list in Tasks, Teams and Settings panel for easier management. Choose a pod which you would use most frequently as Preferred pod.';

export const NO_MULTIPLE_MENTIONS_ERROR = 'The task should have at most one employee mention';

export const UNIVERSAL_SEARCH_RESULTS_LIMIT = {
  team: 4,
  orgTag: 4,
  employeeName: 4,
};

export const UNIVERSAL_SEARCH_RESULTS_LIMIT_MOBILE = {
  employeeName: 10,
};

export const ORDERED_TAG_TYPES = ['TEAM', 'ORGTAG', 'EMPLOYEE_NAME', 'MESH_TASK_TAG', 'GOAL_TAG', 'DEPARTMENT'];

export const GLOBAL_TEAM_INFO_TEXT = (category) =>
  `${category.toProperCase()}s marked as global are visible to everyone within the organization`;

export const PRIVATE_TEAM_INFO_TEXT = (category) =>
  `${category.toProperCase()}s marked as private are visible only to the members of the ${category}`;

export const TASKS_LIST_HEADERS = [
  {
    id: 'STATUS',
    centerAlign: true,
    // paddingRight: '5px',
    sortable: false,
    label: '',
  },
  { id: 'TEXT', paddingLeft: '0', sortable: true, label: 'Initiatives', width: '33%' },
  {
    id: 'PARENT_TASK',
    paddingLeft: '0',
    sortable: false,
    label: 'Parent',
    width: '12%',
  },
  { id: 'TAGS', paddingLeft: '0', sortable: true, label: 'Tags', width: '12%' },
  { id: 'TEAM', paddingLeft: '0', sortable: false, label: 'Owners', width: '16%' },
  // { id: 'TAGS', paddingLeft: '0', sortable: true, label: 'Objective' },
  // { id: 'PROGRESS', paddingLeft: '1.65rem', sortable: false, label: 'Progress' },
  // { id: 'NAME', paddingLeft: '0', paddingRight: '10px', sortable: true, label: 'Team' },
  {
    id: 'ACTIVE_CONTENT',
    paddingLeft: '1.65rem',
    sortable: true,
    label: 'Due On',
    width: '10%',
  },
  // { id: 'STATS', centerAlign: true, sortable: false, label: '' },
  // { id: 'ACTIONS', paddingRight: '2px', label: '' },
  { id: 'DETAILS', label: '', width: '8%' },
  { id: 'BLANK', label: '' },
];

export const TAG_TASKS_LIST_HEADERS = [
  {
    id: 'STATUS',
    centerAlign: true,
    // paddingRight: '5px',
    sortable: false,
    label: '',
  },
  { id: 'TEXT', paddingLeft: '0', sortable: true, label: 'Task Title' },
  { id: 'PARENT_TASK', paddingLeft: '0', sortable: false, label: 'Parent Task' },
  { id: 'TEAM', paddingLeft: '0', sortable: false, label: '' },
  { id: 'NAME', paddingLeft: '0', paddingRight: '10px', sortable: true, label: 'Team' },
  { id: 'ACTIVE_CONTENT', paddingLeft: '1.65rem', sortable: true, label: 'Due On' },
  { id: 'STATS', centerAlign: true, sortable: false, label: '' },
  // { id: 'ACTIONS', paddingRight: '2px', label: '' },
  { id: 'DETAILS', label: '' },
];

export const GOALS_LIST_HEADERS = [
  { id: 'TEXT', paddingLeft: '1.5rem', sortable: true, label: 'Goal Name', width: '35%' },
  { id: 'TAGS', paddingLeft: '1rem', sortable: true, label: 'Objective', width: '15%' },
  { id: 'NAME', paddingLeft: '1rem', sortable: true, label: 'Team', width: '20%' },
  {
    id: 'GOAL_PROGRESS_PERCENTAGE',
    paddingLeft: '1.65rem',
    sortable: true,
    label: 'Progress',
    width: '25%',
  },
  { id: 'DETAILS', label: '', width: '5%' },
];

export const TEAM_TYPE = {
  preferred: 'DEFAULT',
  personal: 'PERSONAL',
  hris: 'HRIS',
  other: 'OTHER',
};

export const TASK_TYPES_1 = {
  task: 'TASK',
  recurrentTask: 'RECURRENT_TASK',
  recurrentInstanceTask: 'RECURRENT_INSTANCE_TASK',
  checkIn: 'CHECK_IN',
};

export const TASK_TYPES_2 = {
  task: 'TASK',
  goal: 'GOAL',
  checkIn: 'CHECK_IN',
  agenda: 'AGENDA_ITEM',
};

const DAYS = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

export const MONTHLY_RECURRENCE_TYPES = [
  {
    label: 'First',
    value: 'MONTH_FIRST',
    recurrenceValues: DAYS,
  },
  {
    label: 'Second',
    value: 'MONTH_SECOND',
    recurrenceValues: DAYS,
  },
  {
    label: 'Third',
    value: 'MONTH_THIRD',
    recurrenceValues: DAYS,
  },
  {
    label: 'Last',
    value: 'MONTH_LAST',
    recurrenceValues: DAYS,
  },
];

export const RECURRENCE_TYPES = [
  {
    label: 'None',
    value: 'NONE',
    recurrenceValues: [],
  },
  {
    label: 'Daily',
    value: 'CUSTOM',
    recurrenceValues: ['DAILY'],
  },
  {
    label: 'Weekly',
    value: 'DAY',
    recurrenceValues: DAYS,
  },
  {
    label: 'Monthly',
    value: 'MONTHLY',
    recurrenceValues: DAYS,
  },
];

export const RECURRENT_SUB_TASK_TYPES = {
  self: 'SELF',
  oneonone: 'ONEONONE',
};

export const REPEATING_TASK_TEXT = 'This is a repeating task';

export const TEAM_VISIBILITY = {
  global: 'GLOBAL',
  private: 'PRIVATE',
};

export const LANDING_PAGE_TYPES = {
  team: 'TEAM',
  task: 'TASK',
  tag: 'HASHTAG',
};

export const GOAL_MEASUREMENT_CURRENCIES = {
  USD: {
    symbol: '$',
    name: 'US Dollar',
    symbolNative: '$',
    isPrefix: true,
    code: 'USD',
    namePlural: 'US dollars',
  },
  CAD: {
    symbol: 'CA$',
    name: 'Canadian Dollar',
    symbolNative: '$',
    isPrefix: true,
    code: 'CAD',
    namePlural: 'Canadian dollars',
  },
  EUR: {
    symbol: '€',
    name: 'Euro',
    symbolNative: '€',
    isPrefix: true,
    code: 'EUR',
    namePlural: 'euros',
  },
  AED: {
    symbol: 'AED',
    name: 'United Arab Emirates Dirham',
    symbolNative: 'د.إ.‏',
    isPrefix: true,
    code: 'AED',
    namePlural: 'UAE dirhams',
  },
  AFN: {
    symbol: 'Af',
    name: 'Afghan Afghani',
    symbolNative: '؋',
    isPrefix: true,
    code: 'AFN',
    namePlural: 'Afghan Afghanis',
  },
  ALL: {
    symbol: 'ALL',
    name: 'Albanian Lek',
    symbolNative: 'Lek',
    isPrefix: true,
    code: 'ALL',
    namePlural: 'Albanian lekë',
  },
  AMD: {
    symbol: 'AMD',
    name: 'Armenian Dram',
    symbolNative: 'դր.',
    isPrefix: true,
    code: 'AMD',
    namePlural: 'Armenian drams',
  },
  ARS: {
    symbol: 'AR$',
    name: 'Argentine Peso',
    symbolNative: '$',
    isPrefix: true,
    code: 'ARS',
    namePlural: 'Argentine pesos',
  },
  AUD: {
    symbol: 'AU$',
    name: 'Australian Dollar',
    symbolNative: '$',
    isPrefix: true,
    code: 'AUD',
    namePlural: 'Australian dollars',
  },
  AZN: {
    symbol: 'man.',
    name: 'Azerbaijani Manat',
    symbolNative: 'ман.',
    isPrefix: true,
    code: 'AZN',
    namePlural: 'Azerbaijani manats',
  },
  BAM: {
    symbol: 'KM',
    name: 'Bosnia-Herzegovina Convertible Mark',
    symbolNative: 'KM',
    isPrefix: true,
    code: 'BAM',
    namePlural: 'Bosnia-Herzegovina convertible marks',
  },
  BDT: {
    symbol: 'Tk',
    name: 'Bangladeshi Taka',
    symbolNative: '৳',
    isPrefix: true,
    code: 'BDT',
    namePlural: 'Bangladeshi takas',
  },
  BGN: {
    symbol: 'BGN',
    name: 'Bulgarian Lev',
    symbolNative: 'лв.',
    isPrefix: true,
    code: 'BGN',
    namePlural: 'Bulgarian leva',
  },
  BHD: {
    symbol: 'BD',
    name: 'Bahraini Dinar',
    symbolNative: 'د.ب.‏',
    isPrefix: true,
    code: 'BHD',
    namePlural: 'Bahraini dinars',
  },
  BIF: {
    symbol: 'FBu',
    name: 'Burundian Franc',
    symbolNative: 'FBu',
    isPrefix: true,
    code: 'BIF',
    namePlural: 'Burundian francs',
  },
  BND: {
    symbol: 'BN$',
    name: 'Brunei Dollar',
    symbolNative: '$',
    isPrefix: true,
    code: 'BND',
    namePlural: 'Brunei dollars',
  },
  BOB: {
    symbol: 'Bs',
    name: 'Bolivian Boliviano',
    symbolNative: 'Bs',
    isPrefix: true,
    code: 'BOB',
    namePlural: 'Bolivian bolivianos',
  },
  BRL: {
    symbol: 'R$',
    name: 'Brazilian Real',
    symbolNative: 'R$',
    isPrefix: true,
    code: 'BRL',
    namePlural: 'Brazilian reals',
  },
  BWP: {
    symbol: 'BWP',
    name: 'Botswanan Pula',
    symbolNative: 'P',
    isPrefix: true,
    code: 'BWP',
    namePlural: 'Botswanan pulas',
  },
  BYN: {
    symbol: 'Br',
    name: 'Belarusian Ruble',
    symbolNative: 'руб.',
    isPrefix: true,
    code: 'BYN',
    namePlural: 'Belarusian rubles',
  },
  BZD: {
    symbol: 'BZ$',
    name: 'Belize Dollar',
    symbolNative: '$',
    isPrefix: true,
    code: 'BZD',
    namePlural: 'Belize dollars',
  },
  CDF: {
    symbol: 'CDF',
    name: 'Congolese Franc',
    symbolNative: 'FrCD',
    isPrefix: true,
    code: 'CDF',
    namePlural: 'Congolese francs',
  },
  CHF: {
    symbol: 'CHF',
    name: 'Swiss Franc',
    symbolNative: 'CHF',
    isPrefix: true,
    code: 'CHF',
    namePlural: 'Swiss francs',
  },
  CLP: {
    symbol: 'CL$',
    name: 'Chilean Peso',
    symbolNative: '$',
    isPrefix: true,
    code: 'CLP',
    namePlural: 'Chilean pesos',
  },
  CNY: {
    symbol: 'CN¥',
    name: 'Chinese Yuan',
    symbolNative: 'CN¥',
    isPrefix: true,
    code: 'CNY',
    namePlural: 'Chinese yuan',
  },
  COP: {
    symbol: 'CO$',
    name: 'Colombian Peso',
    symbolNative: '$',
    isPrefix: true,
    code: 'COP',
    namePlural: 'Colombian pesos',
  },
  CRC: {
    symbol: '₡',
    name: 'Costa Rican Colón',
    symbolNative: '₡',
    isPrefix: true,
    code: 'CRC',
    namePlural: 'Costa Rican colóns',
  },
  CVE: {
    symbol: 'CV$',
    name: 'Cape Verdean Escudo',
    symbolNative: 'CV$',
    isPrefix: true,
    code: 'CVE',
    namePlural: 'Cape Verdean escudos',
  },
  CZK: {
    symbol: 'Kč',
    name: 'Czech Republic Koruna',
    symbolNative: 'Kč',
    isPrefix: true,
    code: 'CZK',
    namePlural: 'Czech Republic korunas',
  },
  DJF: {
    symbol: 'Fdj',
    name: 'Djiboutian Franc',
    symbolNative: 'Fdj',
    isPrefix: true,
    code: 'DJF',
    namePlural: 'Djiboutian francs',
  },
  DKK: {
    symbol: 'Dkr',
    name: 'Danish Krone',
    symbolNative: 'kr',
    isPrefix: true,
    code: 'DKK',
    namePlural: 'Danish kroner',
  },
  DOP: {
    symbol: 'RD$',
    name: 'Dominican Peso',
    symbolNative: 'RD$',
    isPrefix: true,
    code: 'DOP',
    namePlural: 'Dominican pesos',
  },
  DZD: {
    symbol: 'DA',
    name: 'Algerian Dinar',
    symbolNative: 'د.ج.‏',
    isPrefix: true,
    code: 'DZD',
    namePlural: 'Algerian dinars',
  },
  EEK: {
    symbol: 'Ekr',
    name: 'Estonian Kroon',
    symbolNative: 'kr',
    isPrefix: true,
    code: 'EEK',
    namePlural: 'Estonian kroons',
  },
  EGP: {
    symbol: 'EGP',
    name: 'Egyptian Pound',
    symbolNative: 'ج.م.‏',
    isPrefix: true,
    code: 'EGP',
    namePlural: 'Egyptian pounds',
  },
  ERN: {
    symbol: 'Nfk',
    name: 'Eritrean Nakfa',
    symbolNative: 'Nfk',
    isPrefix: true,
    code: 'ERN',
    namePlural: 'Eritrean nakfas',
  },
  ETB: {
    symbol: 'Br',
    name: 'Ethiopian Birr',
    symbolNative: 'Br',
    isPrefix: true,
    code: 'ETB',
    namePlural: 'Ethiopian birrs',
  },
  GBP: {
    symbol: '£',
    name: 'British Pound Sterling',
    symbolNative: '£',
    isPrefix: true,
    code: 'GBP',
    namePlural: 'British pounds sterling',
  },
  GEL: {
    symbol: 'GEL',
    name: 'Georgian Lari',
    symbolNative: 'GEL',
    isPrefix: true,
    code: 'GEL',
    namePlural: 'Georgian laris',
  },
  GHS: {
    symbol: 'GH₵',
    name: 'Ghanaian Cedi',
    symbolNative: 'GH₵',
    isPrefix: true,
    code: 'GHS',
    namePlural: 'Ghanaian cedis',
  },
  GNF: {
    symbol: 'FG',
    name: 'Guinean Franc',
    symbolNative: 'FG',
    isPrefix: true,
    code: 'GNF',
    namePlural: 'Guinean francs',
  },
  GTQ: {
    symbol: 'GTQ',
    name: 'Guatemalan Quetzal',
    symbolNative: 'Q',
    isPrefix: true,
    code: 'GTQ',
    namePlural: 'Guatemalan quetzals',
  },
  HKD: {
    symbol: 'HK$',
    name: 'Hong Kong Dollar',
    symbolNative: '$',
    isPrefix: true,
    code: 'HKD',
    namePlural: 'Hong Kong dollars',
  },
  HNL: {
    symbol: 'HNL',
    name: 'Honduran Lempira',
    symbolNative: 'L',
    isPrefix: true,
    code: 'HNL',
    namePlural: 'Honduran lempiras',
  },
  HRK: {
    symbol: 'kn',
    name: 'Croatian Kuna',
    symbolNative: 'kn',
    isPrefix: true,
    code: 'HRK',
    namePlural: 'Croatian kunas',
  },
  HUF: {
    symbol: 'Ft',
    name: 'Hungarian Forint',
    symbolNative: 'Ft',
    isPrefix: true,
    code: 'HUF',
    namePlural: 'Hungarian forints',
  },
  IDR: {
    symbol: 'Rp',
    name: 'Indonesian Rupiah',
    symbolNative: 'Rp',
    isPrefix: true,
    code: 'IDR',
    namePlural: 'Indonesian rupiahs',
  },
  ILS: {
    symbol: '₪',
    name: 'Israeli New Sheqel',
    symbolNative: '₪',
    isPrefix: true,
    code: 'ILS',
    namePlural: 'Israeli new sheqels',
  },
  INR: {
    symbol: '₹',
    name: 'Indian Rupee',
    symbolNative: 'টকা',
    isPrefix: true,
    code: 'INR',
    namePlural: 'Indian rupees',
  },
  IQD: {
    symbol: 'IQD',
    name: 'Iraqi Dinar',
    symbolNative: 'د.ع.‏',
    isPrefix: true,
    code: 'IQD',
    namePlural: 'Iraqi dinars',
  },
  IRR: {
    symbol: 'IRR',
    name: 'Iranian Rial',
    symbolNative: '﷼',
    isPrefix: true,
    code: 'IRR',
    namePlural: 'Iranian rials',
  },
  ISK: {
    symbol: 'Ikr',
    name: 'Icelandic Króna',
    symbolNative: 'kr',
    isPrefix: true,
    code: 'ISK',
    namePlural: 'Icelandic krónur',
  },
  JMD: {
    symbol: 'J$',
    name: 'Jamaican Dollar',
    symbolNative: '$',
    isPrefix: true,
    code: 'JMD',
    namePlural: 'Jamaican dollars',
  },
  JOD: {
    symbol: 'JD',
    name: 'Jordanian Dinar',
    symbolNative: 'د.أ.‏',
    isPrefix: true,
    code: 'JOD',
    namePlural: 'Jordanian dinars',
  },
  JPY: {
    symbol: '¥',
    name: 'Japanese Yen',
    symbolNative: '￥',
    isPrefix: true,
    code: 'JPY',
    namePlural: 'Japanese yen',
  },
  KES: {
    symbol: 'Ksh',
    name: 'Kenyan Shilling',
    symbolNative: 'Ksh',
    isPrefix: true,
    code: 'KES',
    namePlural: 'Kenyan shillings',
  },
  KHR: {
    symbol: 'KHR',
    name: 'Cambodian Riel',
    symbolNative: '៛',
    isPrefix: true,
    code: 'KHR',
    namePlural: 'Cambodian riels',
  },
  KMF: {
    symbol: 'CF',
    name: 'Comorian Franc',
    symbolNative: 'FC',
    isPrefix: true,
    code: 'KMF',
    namePlural: 'Comorian francs',
  },
  KRW: {
    symbol: '₩',
    name: 'South Korean Won',
    symbolNative: '₩',
    isPrefix: true,
    code: 'KRW',
    namePlural: 'South Korean won',
  },
  KWD: {
    symbol: 'KD',
    name: 'Kuwaiti Dinar',
    symbolNative: 'د.ك.‏',
    isPrefix: true,
    code: 'KWD',
    namePlural: 'Kuwaiti dinars',
  },
  KZT: {
    symbol: 'KZT',
    name: 'Kazakhstani Tenge',
    symbolNative: 'тңг.',
    isPrefix: true,
    code: 'KZT',
    namePlural: 'Kazakhstani tenges',
  },
  LBP: {
    symbol: 'LB£',
    name: 'Lebanese Pound',
    symbolNative: 'ل.ل.‏',
    isPrefix: true,
    code: 'LBP',
    namePlural: 'Lebanese pounds',
  },
  LKR: {
    symbol: 'SLRs',
    name: 'Sri Lankan Rupee',
    symbolNative: 'SL Re',
    isPrefix: true,
    code: 'LKR',
    namePlural: 'Sri Lankan rupees',
  },
  LTL: {
    symbol: 'Lt',
    name: 'Lithuanian Litas',
    symbolNative: 'Lt',
    isPrefix: true,
    code: 'LTL',
    namePlural: 'Lithuanian litai',
  },
  LVL: {
    symbol: 'Ls',
    name: 'Latvian Lats',
    symbolNative: 'Ls',
    isPrefix: true,
    code: 'LVL',
    namePlural: 'Latvian lati',
  },
  LYD: {
    symbol: 'LD',
    name: 'Libyan Dinar',
    symbolNative: 'د.ل.‏',
    isPrefix: true,
    code: 'LYD',
    namePlural: 'Libyan dinars',
  },
  MAD: {
    symbol: 'MAD',
    name: 'Moroccan Dirham',
    symbolNative: 'د.م.‏',
    isPrefix: true,
    code: 'MAD',
    namePlural: 'Moroccan dirhams',
  },
  MDL: {
    symbol: 'MDL',
    name: 'Moldovan Leu',
    symbolNative: 'MDL',
    isPrefix: true,
    code: 'MDL',
    namePlural: 'Moldovan lei',
  },
  MGA: {
    symbol: 'MGA',
    name: 'Malagasy Ariary',
    symbolNative: 'MGA',
    isPrefix: true,
    code: 'MGA',
    namePlural: 'Malagasy Ariaries',
  },
  MKD: {
    symbol: 'MKD',
    name: 'Macedonian Denar',
    symbolNative: 'MKD',
    isPrefix: true,
    code: 'MKD',
    namePlural: 'Macedonian denari',
  },
  MMK: {
    symbol: 'MMK',
    name: 'Myanma Kyat',
    symbolNative: 'K',
    isPrefix: true,
    code: 'MMK',
    namePlural: 'Myanma kyats',
  },
  MOP: {
    symbol: 'MOP$',
    name: 'Macanese Pataca',
    symbolNative: 'MOP$',
    isPrefix: true,
    code: 'MOP',
    namePlural: 'Macanese patacas',
  },
  MUR: {
    symbol: 'MURs',
    name: 'Mauritian Rupee',
    symbolNative: 'MURs',
    isPrefix: true,
    code: 'MUR',
    namePlural: 'Mauritian rupees',
  },
  MXN: {
    symbol: 'MX$',
    name: 'Mexican Peso',
    symbolNative: '$',
    isPrefix: true,
    code: 'MXN',
    namePlural: 'Mexican pesos',
  },
  MYR: {
    symbol: 'RM',
    name: 'Malaysian Ringgit',
    symbolNative: 'RM',
    isPrefix: true,
    code: 'MYR',
    namePlural: 'Malaysian ringgits',
  },
  MZN: {
    symbol: 'MTn',
    name: 'Mozambican Metical',
    symbolNative: 'MTn',
    isPrefix: true,
    code: 'MZN',
    namePlural: 'Mozambican meticals',
  },
  NAD: {
    symbol: 'N$',
    name: 'Namibian Dollar',
    symbolNative: 'N$',
    isPrefix: true,
    code: 'NAD',
    namePlural: 'Namibian dollars',
  },
  NGN: {
    symbol: '₦',
    name: 'Nigerian Naira',
    symbolNative: '₦',
    isPrefix: true,
    code: 'NGN',
    namePlural: 'Nigerian nairas',
  },
  NIO: {
    symbol: 'C$',
    name: 'Nicaraguan Córdoba',
    symbolNative: 'C$',
    isPrefix: true,
    code: 'NIO',
    namePlural: 'Nicaraguan córdobas',
  },
  NOK: {
    symbol: 'Nkr',
    name: 'Norwegian Krone',
    symbolNative: 'kr',
    isPrefix: true,
    code: 'NOK',
    namePlural: 'Norwegian kroner',
  },
  NPR: {
    symbol: 'NPRs',
    name: 'Nepalese Rupee',
    symbolNative: 'नेरू',
    isPrefix: true,
    code: 'NPR',
    namePlural: 'Nepalese rupees',
  },
  NZD: {
    symbol: 'NZ$',
    name: 'New Zealand Dollar',
    symbolNative: '$',
    isPrefix: true,
    code: 'NZD',
    namePlural: 'New Zealand dollars',
  },
  OMR: {
    symbol: 'OMR',
    name: 'Omani Rial',
    symbolNative: 'ر.ع.‏',
    isPrefix: true,
    code: 'OMR',
    namePlural: 'Omani rials',
  },
  PAB: {
    symbol: 'B/.',
    name: 'Panamanian Balboa',
    symbolNative: 'B/.',
    isPrefix: true,
    code: 'PAB',
    namePlural: 'Panamanian balboas',
  },
  PEN: {
    symbol: 'S/.',
    name: 'Peruvian Nuevo Sol',
    symbolNative: 'S/.',
    isPrefix: true,
    code: 'PEN',
    namePlural: 'Peruvian nuevos soles',
  },
  PHP: {
    symbol: '₱',
    name: 'Philippine Peso',
    symbolNative: '₱',
    isPrefix: true,
    code: 'PHP',
    namePlural: 'Philippine pesos',
  },
  PKR: {
    symbol: 'PKRs',
    name: 'Pakistani Rupee',
    symbolNative: '₨',
    isPrefix: true,
    code: 'PKR',
    namePlural: 'Pakistani rupees',
  },
  PLN: {
    symbol: 'zł',
    name: 'Polish Zloty',
    symbolNative: 'zł',
    isPrefix: true,
    code: 'PLN',
    namePlural: 'Polish zlotys',
  },
  PYG: {
    symbol: '₲',
    name: 'Paraguayan Guarani',
    symbolNative: '₲',
    isPrefix: true,
    code: 'PYG',
    namePlural: 'Paraguayan guaranis',
  },
  QAR: {
    symbol: 'QR',
    name: 'Qatari Rial',
    symbolNative: 'ر.ق.‏',
    isPrefix: true,
    code: 'QAR',
    namePlural: 'Qatari rials',
  },
  RON: {
    symbol: 'RON',
    name: 'Romanian Leu',
    symbolNative: 'RON',
    isPrefix: true,
    code: 'RON',
    namePlural: 'Romanian lei',
  },
  RSD: {
    symbol: 'din.',
    name: 'Serbian Dinar',
    symbolNative: 'дин.',
    isPrefix: true,
    code: 'RSD',
    namePlural: 'Serbian dinars',
  },
  RUB: {
    symbol: 'RUB',
    name: 'Russian Ruble',
    symbolNative: '₽.',
    isPrefix: true,
    code: 'RUB',
    namePlural: 'Russian rubles',
  },
  RWF: {
    symbol: 'RWF',
    name: 'Rwandan Franc',
    symbolNative: 'FR',
    isPrefix: true,
    code: 'RWF',
    namePlural: 'Rwandan francs',
  },
  SAR: {
    symbol: 'SR',
    name: 'Saudi Riyal',
    symbolNative: 'ر.س.‏',
    isPrefix: true,
    code: 'SAR',
    namePlural: 'Saudi riyals',
  },
  SDG: {
    symbol: 'SDG',
    name: 'Sudanese Pound',
    symbolNative: 'SDG',
    isPrefix: true,
    code: 'SDG',
    namePlural: 'Sudanese pounds',
  },
  SEK: {
    symbol: 'Skr',
    name: 'Swedish Krona',
    symbolNative: 'kr',
    isPrefix: true,
    code: 'SEK',
    namePlural: 'Swedish kronor',
  },
  SGD: {
    symbol: 'S$',
    name: 'Singapore Dollar',
    symbolNative: '$',
    isPrefix: true,
    code: 'SGD',
    namePlural: 'Singapore dollars',
  },
  SOS: {
    symbol: 'Ssh',
    name: 'Somali Shilling',
    symbolNative: 'Ssh',
    isPrefix: true,
    code: 'SOS',
    namePlural: 'Somali shillings',
  },
  SYP: {
    symbol: 'SY£',
    name: 'Syrian Pound',
    symbolNative: 'ل.س.‏',
    isPrefix: true,
    code: 'SYP',
    namePlural: 'Syrian pounds',
  },
  THB: {
    symbol: '฿',
    name: 'Thai Baht',
    symbolNative: '฿',
    isPrefix: true,
    code: 'THB',
    namePlural: 'Thai baht',
  },
  TND: {
    symbol: 'DT',
    name: 'Tunisian Dinar',
    symbolNative: 'د.ت.‏',
    isPrefix: true,
    code: 'TND',
    namePlural: 'Tunisian dinars',
  },
  TOP: {
    symbol: 'T$',
    name: 'Tongan Paʻanga',
    symbolNative: 'T$',
    isPrefix: true,
    code: 'TOP',
    namePlural: 'Tongan paʻanga',
  },
  TRY: {
    symbol: 'TL',
    name: 'Turkish Lira',
    symbolNative: 'TL',
    isPrefix: true,
    code: 'TRY',
    namePlural: 'Turkish Lira',
  },
  TTD: {
    symbol: 'TT$',
    name: 'Trinidad and Tobago Dollar',
    symbolNative: '$',
    isPrefix: true,
    code: 'TTD',
    namePlural: 'Trinidad and Tobago dollars',
  },
  TWD: {
    symbol: 'NT$',
    name: 'New Taiwan Dollar',
    symbolNative: 'NT$',
    isPrefix: true,
    code: 'TWD',
    namePlural: 'New Taiwan dollars',
  },
  TZS: {
    symbol: 'TSh',
    name: 'Tanzanian Shilling',
    symbolNative: 'TSh',
    isPrefix: true,
    code: 'TZS',
    namePlural: 'Tanzanian shillings',
  },
  UAH: {
    symbol: '₴',
    name: 'Ukrainian Hryvnia',
    symbolNative: '₴',
    isPrefix: true,
    code: 'UAH',
    namePlural: 'Ukrainian hryvnias',
  },
  UGX: {
    symbol: 'USh',
    name: 'Ugandan Shilling',
    symbolNative: 'USh',
    isPrefix: true,
    code: 'UGX',
    namePlural: 'Ugandan shillings',
  },
  UYU: {
    symbol: '$U',
    name: 'Uruguayan Peso',
    symbolNative: '$',
    isPrefix: true,
    code: 'UYU',
    namePlural: 'Uruguayan pesos',
  },
  UZS: {
    symbol: 'UZS',
    name: 'Uzbekistan Som',
    symbolNative: 'UZS',
    isPrefix: true,
    code: 'UZS',
    namePlural: 'Uzbekistan som',
  },
  VEF: {
    symbol: 'Bs.F.',
    name: 'Venezuelan Bolívar',
    symbolNative: 'Bs.F.',
    isPrefix: true,
    code: 'VEF',
    namePlural: 'Venezuelan bolívars',
  },
  VND: {
    symbol: '₫',
    name: 'Vietnamese Dong',
    symbolNative: '₫',
    isPrefix: true,
    code: 'VND',
    namePlural: 'Vietnamese dong',
  },
  XAF: {
    symbol: 'FCFA',
    name: 'CFA Franc BEAC',
    symbolNative: 'FCFA',
    isPrefix: true,
    code: 'XAF',
    namePlural: 'CFA francs BEAC',
  },
  XOF: {
    symbol: 'CFA',
    name: 'CFA Franc BCEAO',
    symbolNative: 'CFA',
    isPrefix: true,
    code: 'XOF',
    namePlural: 'CFA francs BCEAO',
  },
  YER: {
    symbol: 'YR',
    name: 'Yemeni Rial',
    symbolNative: 'ر.ي.‏',
    isPrefix: true,
    code: 'YER',
    namePlural: 'Yemeni rials',
  },
  ZAR: {
    symbol: 'R',
    name: 'South African Rand',
    symbolNative: 'R',
    isPrefix: true,
    code: 'ZAR',
    namePlural: 'South African rand',
  },
  ZMK: {
    symbol: 'ZK',
    name: 'Zambian Kwacha',
    symbolNative: 'ZK',
    isPrefix: true,
    code: 'ZMK',
    namePlural: 'Zambian kwachas',
  },
  ZWL: {
    symbol: 'ZWL$',
    name: 'Zimbabwean Dollar',
    symbolNative: 'ZWL$',
    isPrefix: true,
    code: 'ZWL',
    namePlural: 'Zimbabwean Dollar',
  },
};

export const GOAL_MEASUREMENT_TYPES = [
  {
    symbol: null,
    name: '',
    code: 'CURRENCY',
    isPrefix: true,
  },
  {
    symbol: '%',
    name: 'Percentage',
    code: 'PERCENTAGE',
    isPrefix: false,
  },
  {
    symbol: '#',
    name: 'Count',
    code: 'COUNT',
    isPrefix: false,
  },
];

export const TASK_STATUS = {
  ongoing: {
    label: 'Ongoing',
    value: 'ONGOING',
    color: 'orange',
  },
  delayed: {
    label: 'Delayed',
    value: 'DELAYED',
    color: 'red',
  },
  unplanned: {
    label: 'Unplanned',
    value: 'UNPLANNED',
    color: 'blue',
  },
  completed: {
    label: 'Completed',
    value: 'COMPLETED',
    color: 'green',
  },
};

export const TASK_DETAILED_STATUS = {
  delayed: 'DELAYED',
  ongoing: 'ONGOING',
  completedWithDelay: 'COMPLETED_WITH_DELAY',
  completedOnTime: 'COMPLETED_ON_TIME',
};

export const TENOR_ENDPOINT = `${process.env.REACT_APP_HTTPS === 'true' ? 'https' : 'http'}://api.tenor.com/v1/`;

export const PLAN_TYPE = {
  enterprise: 'ENTERPRISE',
};

export const TAG_ID_TYPE = {
  subTask: 'SUBTASK',
  tagged: 'TAGGED;',
  explicit: 'EXPLICIT',
};

export const TASK_OPTION_ID = {
  goal: -4,
  checkIn: -5,
  all: -1,
  personal: -2, // TODO: confirm that personal is -2 only
};

export const MARKETING_SITE_CONTACT_US_ROUTE = 'https://www.mesh.ai/contact-us';

export const MARKETING_SITE = 'https://www.mesh.ai/';

export const MESH_TAG_TYPES = [
  {
    value: 'MESH_TASK',
    label: 'Initiative Tags',
    singularLabel: 'Task Tag',
    tagLabel: 'Tag',
    isEnterpriseAccessOnly: false,
  },
  {
    value: 'GOAL',
    label: 'Objectives',
    singularLabel: 'Objective',
    tagLabel: 'Objective',
    isEnterpriseAccessOnly: false,
  },
  {
    value: 'CORE_COMPETENCIES',
    label: 'Competencies',
    singularLabel: 'Competency',
    tagLabel: 'Name',
    isEnterpriseAccessOnly: true,
  },
  {
    value: 'ENGAGEMENT_DRIVER',
    label: 'Drivers',
    singularLabel: 'Driver',
    tagLabel: 'Driver',
    isEnterpriseAccessOnly: true,
  },
];

export const MACROS_DISPLAY_PATTERN = /(.?{{.*?}})/gm;

export const NOTIFICATION_POLL_INTERVAL = 1000 * 6 * 5;

export const LOGIN_TYPES = {
  mesh: 'MESH',
  saml: 'SAML',
  google: 'GOOGLE',
  jira: 'JIRA',
};

export const EMPLOYEE_FIELDS_LIST = [
  {
    id: 'firstName',
    title: 'First Name',
    type: 'text',
    defaultValue: '',
    isRequired: true,
    showInProfile: true,
  },
  {
    id: 'lastName',
    title: 'Last Name',
    type: 'text',
    defaultValue: '',
    showInProfile: true,
  },
  {
    id: 'preferredName',
    title: 'Preferred Name',
    type: 'text',
    defaultValue: '',
    showInProfile: true,
  },
  {
    id: 'gender',
    title: 'Gender',
    type: 'select',
    defaultValue: null,
    options: Object.keys(GENDERS).map((gender) => ({
      value: GENDERS[gender].key,
      label: GENDERS[gender].label,
    })),
    showInProfile: true,
  },
  {
    id: 'ethnicity',
    title: 'Ethnicity',
    type: 'creatableSelect',
    defaultValue: null,
    options: ETHNICITIES.map((dpt) => ({ value: dpt, label: dpt })),
    showInProfile: true,
  },
  {
    id: 'email',
    title: 'Email',
    type: 'text',
    defaultValue: '',
    isRequired: true,
    showInProfile: true,
  },
  {
    id: 'managerEmail',
    title: 'Manager Email',
    type: 'email-autocomplete',
    defaultValue: '',
    options: [],
    showInProfile: true,
  },
  {
    id: 'joiningDate',
    title: 'Joining Date',
    type: 'date',
    defaultValue: undefined,
    showInProfile: true,
  },
  {
    id: 'dateOfBirth',
    title: 'Date of Birth',
    type: 'date',
    defaultValue: undefined,
    showInProfile: true,
  },
  {
    id: 'country',
    title: 'Country',
    type: 'text',
    defaultValue: '',
    showInProfile: true,
  },
  {
    id: 'employeeAccesses',
    title: 'Admin Access',
    type: 'customMultiSelect',
    defaultValue: null,
    options: [],
    isRequired: false,
    showInProfile: true,
  },
  {
    id: 'designation',
    title: 'Designation',
    type: 'text',
    defaultValue: '',
    showInProfile: true,
  },
  // {
  //   id: 'department',
  //   title: 'Department',
  //   type: 'asyncCreatableSelect',
  //   defaultValue: null,
  //   options: DEPARTMENTS.map(dpt => ({ value: dpt, label: dpt })),
  //   showInProfile: true
  // },
  {
    id: 'location',
    title: 'Location',
    type: 'text',
    defaultValue: '',
    showInProfile: true,
  },
  // {
  //   id: 'designation',
  //   title: 'Role',
  //   type: 'text',
  //   defaultValue: '',
  //   showInProfile: true
  // },
  // {
  //   id: 'primaryPod',
  //   title: 'Primary Pod',
  //   type: 'primaryPod-select',
  //   defaultValue: null,
  //   showInProfile: false
  // }
  // {
  //   id: 'secondaryPods',
  //   title: 'Secondary Pods',
  //   type: 'secondaryPods-select',
  //   defaultValue: null,
  //   showInProfile: false
  // }
  // {
  //   id: 'employeeFunction',
  //   title: 'Function',
  //   type: 'text',
  //   defaultValue: null,
  //   showInProfile: true
  // },
  // {
  //   id: 'grade',
  //   title: 'Grade',
  //   type: 'text',
  //   defaultValue: null,
  //   showInProfile: false
  // }
];

// eslint-disable-next-line no-useless-escape
export const EMPLOYEE_NAME_REGEX = /^[^<>\"']*$/;

export const UPLOAD_FILE_HEADER =
  'email,firstName,lastName,department,designation,location,country,gender,managerEmail,joiningDate,dateOfBirth,userRole';
export const UPLOAD_SAMPLE_ROW =
  'hulk@avengers.team,Bruce,Banner,Biotechnology,Scientist,NewYork,USA,male,,11-04-2012,18-12-1969,EMPLOYEE';
export const CHECK_IN_TASK_DEFAULT_DESCRIPTION =
  'This is a private space between you and your manager. No details recorded here are visible to anyone else across your teams. 1:1 check-ins help your manager know you better and drive your growth. You and your manager can summarize discussions from 1:1s and add action items agreed for your growth & development.';

export const CHECK_IN_COMMENT_PLACEHOLDER = 'Share comments on talking point here';
export const CHECK_IN_MEETING_NOTES_PLACEHOLDER = 'Share meeting notes here';

export const USER_ROLES_ENUM = {
  admin: 'ADMIN',
  employee: 'EMPLOYEE',
};

export const sampleRosterFileURI = 'https://media.mesh.ai/mesh/media/MeshBulkUploadSample-4.csv';

export const sampleObjectiveFileCompactURI =
  'https://meshai-media.s3.ap-south-1.amazonaws.com/mesh/media/bulk_upload_templates/objective_upload_template+-+COMPACT.csv';
export const sampleObjectiveFileDetailedURI =
  'https://meshai-media.s3.ap-south-1.amazonaws.com/mesh/media/bulk_upload_templates/objective_upload_template+-+DETAILED.csv';
export const sampleObjectiveFileSimpleURI =
  'https://meshai-media.s3.ap-south-1.amazonaws.com/mesh/media/bulk_upload_templates/objective_upload_template+-+SIMPLE.csv';

export const sampleNewGoalFileURI =
  'https://meshai-media.s3.ap-south-1.amazonaws.com/mesh/media/bulk_upload_templates/goal_upload_template+-+KR.csv';
export const sampleObjectiveFileURI = 'https://media.mesh.ai/mesh/media/ObjectiveUploadSample.csv';
export const sampleGoalFileURI = 'https://media.mesh.ai/mesh/media/OkrUploadSample.csv';
export const sampleCompetencyFileURI = 'https://media.mesh.ai/mesh/media/CompetencyUploadSample.csv';
export const sampleCompetencyFunctionAndRoleFileURI =
  'https://media.mesh.ai/mesh/media/FunctionAndRoleSpecificCompetencyUpload.csv';

export const sampleEmployeeSelectionFileURI = 'https://media.mesh.ai/mesh/media/EmployeeCSVSample.csv';

export const NoPodManagerErrorText = (teamName) =>
  `Please request admin to assign manager role to one of the members in ${teamName} pod.`;

export const userguideJSONURI = 'https://media.mesh.ai/mesh/media/userguide.json';
export const troubleshootJSONURI = 'https://media.mesh.ai/mesh/media/troubleshoot.json';

export const demoVideoURI = 'https://media.mesh.ai/ProductDemo.mp4';

export const CHECKINS_FREQUENCY_OPTIONS = [
  {
    label: 'Weekly',
    value: 'WEEKLY',
  },
  // {
  //   label: 'Fortnightly',
  //   value: 'FORTNIGHTLY'
  // },
  {
    label: 'Monthly',
    value: 'MONTHLY',
  },
];

export const APPLAUSES_FREQUENCY = ['MONTHLY', 'QUARTERLY', 'YEARLY'];

export const APPLAUSE_AMOUNT_HELP_TEXT =
  "At the chosen frequency, every employee's wallet balance will be reset to the following amount";

export const TASK_TYPE_FILTER_OPTIONS = [
  // {
  //   value: 'TASK',
  //   label: 'Task'
  // },
  {
    value: 'SUB_TASK',
    label: 'Task',
  },
];

export const REVIEW_FORMS_TYPE_MAP = {
  COMPETENCY: 'COMPETENCY',
  QNA: 'QNA',
  GOAL: 'GOAL',
  PEER_NOMINATION: 'PEER_NOMINATION',
};

export const REVIEW_STAGES = {
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

// todo: remove this one after cleanup. use the REVIEW_RESPONSE_PROVIDER_TYPE_TITLE_MAP_1 and append 'Review' whereever required
export const REVIEW_RESPONSE_PROVIDER_TYPE_TITLE_MAP = {
  SELF: 'Self Review',
  MANAGER: 'Manager Review',
  PEER: 'Peer Review',
  UPWARD: 'Upward Review',
  NOMINATOR: 'Nomination',
  DLM: 'Dotted Line Manager Review',
};

export const REVIEW_RESPONSE_PROVIDER_TYPE_TITLE_MAP_1 = {
  SELF: 'Self',
  MANAGER: 'Manager',
  PEER: 'Peer',
  UPWARD: 'Upward',
  NOMINATOR: 'Nomination',
  DLM: 'Dotted Line Manager',
};

export const REVIEW_SUBMISSION_STATUS_MAP = {
  NOT_STARTED: {
    actionText: 'Start',
    statusText: 'Not Started',
    textColor: theme.palette.red.main,
    bgColor: theme.palette.red.light,
    Icon: Play,
    infoChipColor: 'black',
  },
  IN_PROGRESS: {
    actionText: 'Continue',
    statusText: 'In progress',
    textColor: theme.palette.orange.main,
    bgColor: theme.palette.orange.light,
    Icon: Continue,
    infoChipColor: 'yellow',
  },
  SUBMITTED: {
    actionText: 'View Result',
    statusText: 'Completed',
    textColor: theme.palette.green.main,
    bgColor: theme.palette.green.light,
    infoChipColor: 'green',
  },
  ACKNOWLEDGED: {
    actionText: 'View Result',
    statusText: 'Acknowledged',
    textColor: theme.palette.green.main,
    bgColor: theme.palette.green.light,
    infoChipColor: 'green',
  },
  PUBLISHED: {
    actionText: 'Published',
    statusText: 'Published',
    textColor: theme.palette.green.main,
    bgColor: theme.palette.green.light,
    infoChipColor: 'darkGreen',
  },
};

export const REVIEW_ANSWER_TYPE_MAP = {
  DROP_DOWN: 'DROP_DOWN',
  NONE: 'NONE',
  MULTI_SELECT: 'MULTI_SELECT',
  STAR_RATING: 'STAR_RATING',
  NUMBER_RATING: 'NUMBER_RATING',
};

export const REVIEW_FORM_COMPLETION_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  SUBMITTED: 'SUBMITTED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
  PUBLISHED: 'PUBLISHED',
};

export const REVIEW_TEXTS = {
  GOALS_NOT_ENABLED: {
    LINE1: 'This feature is not yet activated for your organization and will be used in future review cycles.',
    LINE2: 'Please request your admin to enable this feature',
  },
  NO_GOALS_TEXT: {
    LINE1: 'You currently do not have any goals/OKRs created on Mesh.',
    LINE2: 'Please connect with your manager to discuss and create your goals/OKRs for the next cycle.',
  },
  NO_STRENGTHS_TEXT: {
    LINE1: 'You currently do not have any strengths created on Mesh.',
    LINE2: 'Please connect with your manager to discuss and create your strengths for the next cycle.',
  },
  GOALS: {
    NO_DATA: 'No Goals to display.',
    ERROR_MSG: 'Some error occurred while fetching goals.',
  },
  CORE_COMPETENCIES: {
    NO_DATA: 'No Core competencies to display.',
    ERROR_MSG: 'Some error occurred while fetching core competencies.',
  },
  QA: {
    NO_DATA: 'No Questions to display.',
    ERROR_MSG: 'Some error occurred while fetching questions.',
  },
  LANDING_PAGE_REVIEWS_TO_COMPLETE: {
    NO_DATA: 'No on-going reviews.',
    ERROR_MSG: 'Request to fetch on-going reviews failed!',
  },
  LANDING_PAGE_ARCHIVED_REVIEWS: {
    NO_DATA: 'No completed reviews.',
    ERROR_MSG: 'Request to fetch completed reviews failed!',
  },
  LANDING_PAGE_TEAM_STATUS: {
    NO_DATA: 'No Data to display',
    ERROR_MSG: 'Request to fetch Team status failed!',
  },
  REVIEW_PROVIDER_HEADER: {
    NO_DATA: 'No Data to display',
    ERROR_MSG: 'Request to fetch Review Providers list failed!',
  },
};

export const ANALYTICS_TEXTS = {
  REVIEW_TABLE: {
    NO_DATA: 'No Data to display',
    ERROR_MSG: 'Request to fetch data failed. Please try again later.',
  },
  REVIEW: {
    CALIBRATION: {
      NO_DATA: 'No Data to display',
      ERROR_MSG: 'Request to fetch data failed. Please try again later.',
    },
  },
  COHORT: {
    NO_DATA:
      'There isn’t any information for the selected cohort/employee. \n You can go back or change time period or update selected cohort.',
  },
};

export const SLACK_PRIVATE_CHANNEL_INFO_TEXT =
  "To get private channels, open 'Channel details' tab, choose 'More' in dropdown menu, choose 'Add apps' and add 'Mesh' app";

// this is to be depreciated. Don't use this going forward. Instead use following maps.
export const ORG_ID = {
  1064: 'mesh',
  1194: 'meshDemo',
  1722: 'meshDemo2',
  1283: 'sharechat',
  1259: 'cred',
  1733: 'cred2',
  1692: 'pipefy',
  2020: 'kitabisa',
  2075: 'zeta.tech',
};

export const ORG_ID_TO_NAME_MAP = ORG_ID;

export const ORG_NAME_TO_ID_MAP = Object.entries(ORG_ID_TO_NAME_MAP).reduce((mappedObj, [id, name]) => {
  // eslint-disable-next-line no-param-reassign
  mappedObj[name] = id;
  return mappedObj;
}, {});

export const TASK_SHORT_STATUS = {
  toBeDone: {
    label: 'To be done',
    color: 'orange',
    key: 'ACTIVE',
  },
  completed: {
    label: 'Completed',
    color: 'green',
    key: 'COMPLETED',
  },
};

export const REVIEW_CYCLE_EVENTS = {
  NOMINATOR: {
    name: 'Nomination',
    description: 'Reviewee will nominate peers for review',
  },
  SELF: {
    name: 'Self review',
    description: 'All reviewers will complete their self reviews',
  },
  PEER: {
    name: 'Peer review',
    description: 'Reviewee will nominate peers for review',
  },
  UPWARD: {
    name: 'Upward review',
    description: 'Reviewees will receive review from their team members',
  },
  MANAGER: {
    name: 'Manager review',
    description: 'All managers will provide review for their team members',
  },
  DLM: {
    name: 'Dotted Line Manager review',
    description: 'All dotted line managers will provide review for their team members',
  },
  RATING_CALIBRATE: {
    name: 'Publish',
    description: 'Manager finalized summary & results are shared',
  },
};

export const MANAGER_DELIVERABLE_OPTIONS = [
  {
    value: 'SHARE',
    label: 'Share the review and scores with their team member',
  },
  {
    value: 'SHARE_TEXT_ONLY',
    label: 'Share only the review with the team member',
  },
  {
    value: 'DONT_SHARE',
    label: "Don't share the review with their team member",
  },
];

export const PEER_DELIVERABLE_OPTIONS = [
  {
    value: 'SHARE',
    label: 'Share the review with the reviewers name',
  },
  {
    value: 'ANONYMIZE',
    label: 'Share the review anonymously',
  },
  {
    value: 'DONT_SHARE',
    label: "Don't share review at all",
  },
];

const JIRA_PLUGIN = {
  value: 'JIRA',
  label: 'Jira',
};

const SALESFORCE_PLUGIN = {
  value: 'SALESFORCE',
  label: 'Salesforce',
};

const DARWINBOX_PLUGIN = {
  value: 'DARWINBOX',
  label: 'Darwinbox',
};

export const TRACKING_OPTIONS = [JIRA_PLUGIN, SALESFORCE_PLUGIN, DARWINBOX_PLUGIN];

export const TRACKING_DISABLE_TOOLTIP =
  'This plugin is not enabled, please contact Mesh Admin to enable this for your organization';

export const TRACKING_OPTIONS_MAP = {
  JIRA_PLUGIN,
  SALESFORCE_PLUGIN,
  DARWINBOX_PLUGIN,
};

export const GOAL_PROGRESS_TYPE_OPTIONS = {
  MAX: {
    heading: 'Maximum Progress',
    subHeading: 'Goals with most progress so far',
  },
  MIN: {
    heading: 'Minimum Progress',
    subHeading: 'Goals with least progress so far',
  },
};

export const GOAL_PROGRESS_CHART_COLORS = {
  progress: theme.palette.primary.main,
  checkIns: '#c0c0c0',
};

export const VISIBLE_PROVIDER_TYPES_COLOR_MAPPING = {
  SELF: 'orange',
  MANAGER: 'darkGreen',
  PEER: 'blue',
  UPWARD: 'red',
  DLM: 'purpleChart',
};

// For Performance Review Graph on the Review page.
export const REVIEW_CYCLE_RATING_SPLIT_COLOR_MAPPING = {
  PERFORMANCE: 'darkGreen',
  OVERALL: 'darkGreen',
  POTENTIAL: 'darkGreen',
  QNA: 'darkGreen',
};

// Can be of many stage types apart from PUBLISHED
export const REVIEW_INFO_FEEDBACK_FORM_CURRENT_STAGE = {
  PUBLISHED: 'PUBLISHED',
};

export const REVIEW_CYCLE_STEPS = [
  'NAME',
  'REVIEWEES',
  'REVIEWERS',
  'RATING_SETTINGS',
  'REVIEW_SETTINGS',
  'DELIVERABLES',
  'TIMELINES',
  'REMINDERS',
  'VALIDATION',
  'SUMMARY',
];

export const PULSE_KICKSTART_STEPS = [
  'SURVEY_NAME',
  'SURVEY_PARTICIPANTS',
  'SURVEY_TEMPLATE',
  'SURVEY_SETTINGS',
  'SURVEY_REMINDERS',
  'SURVEY_VALIDATION',
  'SURVEY_SUMMARY',
];

export const FEEDBACK_FORM_TYPE = {
  PROVIDED: 'PROVIDED',
  REQUESTED: 'REQUESTED',
  PROVIDING_REQUESTED: 'PROVIDING_REQUESTED',
  SHOW_PROVIDED: 'SHOW_PROVIDED',
  SHOW_REQUESTED: 'SHOW_REQUESTED',
  SHOW_RECEIVED: 'SHOW_RECEIVED',
  LIST_FEEDBACKS: 'LIST_FEEDBACKS',
};

export const FEEDBACK_FORM_VISIBILITY = [
  {
    value: 'PUBLIC',
    label: 'Public',
    icon: <Public />,
  },
  {
    value: 'RECEIVER_AND_MANAGER',
    label: 'Receiver and manager',
    icon: <ReceiverManager />,
  },

  {
    value: 'RECEIVER',
    label: 'Only receiver',
    icon: <Person />,
  },

  {
    value: 'MANAGER',
    label: 'Only manager ',
    icon: <ManagerRabbit />,
  },
  {
    value: 'NONE',
    label: 'Note to self',
    icon: <BulletList />,
  },
];

export const FEEDBACK_FORM_VISIBILITY_RABBIT = [
  {
    value: 'PUBLIC',
    label: 'Public',
    icon: 'PublicViewIcon',
  },
  {
    value: 'RECEIVER_AND_MANAGER',
    label: 'Receiver and manager',
    icon: 'PrivateManagerViewIcon',
  },

  {
    value: 'RECEIVER',
    label: 'Only receiver',
    icon: 'PrivateViewIcon',
  },

  {
    value: 'MANAGER',
    label: 'Only manager ',
    icon: 'ManagerViewIcon',
  },
  {
    value: 'NONE',
    label: 'Note to self',
    icon: 'Details',
  },
];

export const FEEDBACK_FORM_VISIBILITY_RABBIT_WITHOUT_PUBLIC = [
  {
    value: 'RECEIVER_AND_MANAGER',
    label: 'Receiver and manager',
    icon: 'PrivateManagerViewIcon',
  },

  {
    value: 'RECEIVER',
    label: 'Only receiver',
    icon: 'PrivateViewIcon',
  },

  {
    value: 'MANAGER',
    label: 'Only manager ',
    icon: 'ManagerViewIcon',
  },
  {
    value: 'NONE',
    label: 'Note to self',
    icon: 'Details',
  },
];

export const SHARE_FEEDBACK_TYPES = [
  {
    value: SENTIMENTS.appreciative,
    label: 'Praise',
    icon: 'PositiveSentiment',
  },
  {
    value: SENTIMENTS.constructive,
    label: 'Feedback',
    icon: 'NegativeSentiment',
  },
  // {
  //   value: SENTIMENTS.neutral,
  //   label: 'Neutral',
  //   icon: 'NeutralSentiment'
  // }
];

export const SHARE_FEEDBACK_TYPES_V2 = [
  {
    value: SENTIMENTS.appreciative,
    label: 'Praise',
    icon: (props) => <Praise fill="transparent" {...props} />,
  },
  {
    value: SENTIMENTS.constructive,
    label: 'Feedback',
    icon: (props) => <Advice fill="transparent" {...props} />,
  },
];

export const SHARE_FEEDBACK_TYPES_FOR_AUTOPOPULATE = [
  {
    value: SENTIMENTS.appreciative,
    label: 'Praise',
    icon: 'PositiveSentiment',
  },
  {
    value: SENTIMENTS.constructive,
    label: 'Feedback',
    icon: 'NegativeSentiment',
  },
];

export const SENTIMENTS_TYPES = {
  appreciative: {
    value: SENTIMENTS.appreciative,
    label: 'Appreciative',
  },
  constructive: {
    value: SENTIMENTS.constructive,
    label: 'Constructive',
  },
  neutral: {
    value: SENTIMENTS.neutral,
    label: 'Neutral',
  },
};

export const SENTIMENT_TOGGLE_ORDER = [SENTIMENTS.neutral, SENTIMENTS.appreciative, SENTIMENTS.constructive];

export const CONTINUOUS_FEEDBACK_FORM_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  COMPLETED: 'SUBMITTED',
};

export const CONTINUOUS_FEEDBACK_PEER_STATUS = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  NOMINATED: 'NOMINATED',
  SUBMITTED: 'SUBMITTED',
};

export const REVIEW_PEER_STATUS = {
  ACCEPTED: 'ACCEPTED',
  DECLINED: 'DECLINED',
  NOMINATED: 'NOMINATED',
};

export const NOMINATION_PANEL_CLOSING_STATUS = {
  CANCELLED: 'CANCELLED',
  SUCCESSFUL: 'SUCCESSFUL',
};

export const CONTINUOUS_FEEDBACK_RELOAD_TYPE = {
  ARCHIVED: 'ARCHIVED',
  SHARED_OWN: 'SHARED_OWN',
  PROVIDED_REQUESTED: 'PROVIDED_REQUESTED',
};

export const FEEDBACK_COMMENT_STRINGIFIED_RAW_STATE =
  '{"blocks":[{"key":"9hun0","text":"Continuous Feedback*","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}';

export const MOST_LEAST_ACTIVE_MANAGER_META = {
  MOST: {
    heading: 'Most active managers',
    subHeading: 'These are managers with most 1:1s every x days',
  },
  LEAST: {
    heading: 'Least active managers',
    subHeading: 'These are managers with least 1:1s every x days',
  },
};

export const MOST_LEAST_ACTIVE_EMPLOYEE_META = {
  MOST: {
    heading: 'Top Performers',
    subHeading: 'They are the most high performing in your organization',
  },
  LEAST: {
    heading: 'Top Laggards',
    subHeading: 'They are the most inactive in your organization',
  },
};

export const MOST_LEAST_ACTIVE_REPORTEE_META = {
  MOST: {
    heading: 'Most Active Team Member',
    subHeading: 'These are your most active team members',
  },
  LEAST: {
    heading: 'Most Inactive Team Members',
    subHeading: 'These are your most inactive team members',
  },
};

export const GOAL_ANALYTICS_VIEW_IDS = {
  OBJECTIVE: 'objective',
  INDIVIDUAL: 'employeeId',
};

export const FEEDBACK_INSIGHTS_VIEW_IDS = {
  CORE_VALUES: 'competency',
  INDIVIDUAL: 'employeeId',
};
export const ALERT_MESSAGES = {
  CONTINUOUS_FEEDBACK: {
    SHARE: {
      SUCCESS: 'Feedback shared successfully!',
      FAILURE: 'An error occurred while sharing the feedback!',
    },
    REQUEST: {
      SUCCESS: 'Feedback request sent',
      FAILURE: 'An error occurred while sending the feedback request!',
    },
    ARCHIVE: {
      SUCCESS: 'Feedback archived',
      FAILURE: 'An error occurred while archiving the feedback!',
    },
    COMMENT: {
      EMPTY: 'Please comment something!',
    },
  },
  GENERIC: {
    FAILURE: 'Oops! something went wrong!',
    SUCCESS: 'Process succeeded',
  },
};

export const FEEDBACK_STRENGTH_TYPES = {
  APPRECIATIVE: SHARE_FEEDBACK_TYPES[0].label,
  CONSTRUCTIVE: SHARE_FEEDBACK_TYPES[1].label,
};

export const ANSWER_TYPES = {
  review: [
    {
      label: 'Score',
      value: 'STAR_RATING',
    },
    {
      label: 'Scale',
      value: 'NUMBER_RATING',
    },
    {
      label: 'Options',
      value: 'DROP_DOWN',
    },
    {
      label: 'Text',
      value: 'NONE',
    },
  ],
  engagement: [
    {
      label: 'Emoji',
      value: 'EMOJI',
    },
    {
      label: 'Number',
      value: 'NUMBER_RATING',
    },
    {
      label: 'Options',
      value: 'DROP_DOWN',
    },
    {
      label: 'Text',
      value: 'NONE',
    },
  ],
  settings: [
    {
      label: 'Options',
      value: 'DROP_DOWN',
      isDisabled: false,
    },
    {
      label: 'Numeric',
      value: 'NUMBER_RATING',
      isDisabled: true,
    },
  ],
  one_on_one: [
    {
      label: 'Comments',
      value: 'COMMENT',
    },
    {
      label: 'Emoji',
      value: 'EMOJI',
    },
  ],
};

export const QUESTION_TYPES = {
  review: [
    {
      label: 'Goal',
      value: 'GOAL',
    },

    {
      label: 'Generic',
      value: 'QNA',
    },
    {
      label: 'Competency',
      value: 'COMPETENCY',
    },
  ],
  engagement: [],
  one_on_one: [
    { label: 'Emotion', value: 'EMOTION' },
    {
      label: 'Talking Point',
      value: 'TALKING_POINT',
    },
  ],
};

export const QUESTION_FORM_SOURCES = {
  ENGAGEMENT: 'engagement',
  REVIEW: 'review',
  ONE_ON_ONE: 'one_on_one',
};

export const QUESTION_MENU_OPTIONS = {
  active: ['DUPLICATE', 'COPY_LINK', 'ARCHIVE'],
  sample: ['DUPLICATE'],
  archived: ['DUPLICATE', 'COPY_LINK', 'UNARCHIVE'],
};

// Review Template
export const REVIEW_TEMPLATE_MENU_OPTIONS = {
  active: ['DUPLICATE', 'ARCHIVE'],
  archived: ['DUPLICATE', 'UNARCHIVE'],
};

/* Date Range options */
export const yesterday = {
  label: 'Yesterday',
  startDate: subDays(startOfDay(new Date()), 1),
  endDate: subDays(endOfDay(new Date()), 1),
};

export const lastWeek = {
  label: 'Last Week',
  startDate: subWeeks(startOfWeek(new Date()), 1),
  endDate: subWeeks(endOfWeek(new Date()), 1),
};

export const lastTwoWeeks = {
  label: 'Last 2 Weeks',
  startDate: subWeeks(startOfWeek(new Date()), 2),
  endDate: subWeeks(endOfWeek(new Date()), 1),
};

export const thisQuarter = {
  label: 'This Quarter',
  startDate: startOfQuarter(new Date()),
  endDate: endOfQuarter(new Date()),
};

export const upcomingQuarter = {
  label: 'Upcoming Quarter',
  startDate: startOfQuarter(addQuarters(new Date(), 1)),
  endDate: endOfQuarter(addQuarters(new Date(), 1)),
};

export const lastQuarter = {
  label: 'Last Quarter',
  startDate: subMonths(startOfQuarter(new Date()), 3),
  endDate: subSeconds(startOfQuarter(new Date()), 1),
};

export const lastTwoQuarters = {
  label: 'Last 2 Quarters',
  startDate: subMonths(startOfQuarter(new Date()), 6),
  endDate: subSeconds(startOfQuarter(new Date()), 1),
};

export const thisMonth = {
  label: 'This Month',
  startDate: startOfMonth(new Date()),
  endDate: endOfMonth(new Date()),
};

export const upcomingMonth = {
  label: 'Upcoming Month',
  startDate: startOfMonth(addMonths(new Date(), 1)),
  endDate: endOfMonth(addMonths(new Date(), 1)),
};

export const upcomingWeek = {
  label: 'Upcoming Week',
  startDate: startOfWeek(addWeeks(new Date(), 1)),
  endDate: endOfWeek(addWeeks(new Date(), 1)),
};

export const lastMonth = {
  label: 'Last Month',
  startDate: subMonths(startOfMonth(new Date()), 1),
  endDate: subSeconds(startOfMonth(new Date()), 1),
};

export const lastTwoMonths = {
  label: 'Last 2 Months',
  startDate: subMonths(startOfMonth(new Date()), 2),
  endDate: subSeconds(startOfMonth(new Date()), 1),
};

export const thisYear = {
  label: 'This Year',
  startDate: startOfYear(new Date()),
  endDate: endOfYear(new Date()),
};

export const past6Months = {
  label: 'Past 6 Months',
  startDate: subMonths(startOfDay(new Date()), 6),
  endDate: subSeconds(endOfDay(new Date()), 0),
};

export const past12Months = {
  label: 'Past 12 months',
  startDate: subYears(startOfDay(new Date()), 1),
  endDate: subSeconds(endOfDay(new Date()), 0),
};

export const past3Months = {
  label: 'Past 3 Months',
  startDate: subMonths(startOfDay(new Date()), 3),
  endDate: subSeconds(endOfDay(new Date()), 0),
};

export const ANALYTICS_PREDEFINED_DATERANGES = [
  thisQuarter,
  lastQuarter,
  lastTwoQuarters,
  thisMonth,
  lastMonth,
  lastTwoMonths,
  thisYear,
  past3Months,
];

export const TEAMHUB_PREDEFINED_DATERANGES = ANALYTICS_PREDEFINED_DATERANGES;

export const TEAMHUB_LAST_ACTIVITY_PREDEFINED_DATERANGES = [yesterday, lastWeek, lastTwoWeeks, lastMonth];

export const TEAMHUB_LAST_ACTIVITY_DATERANGE_ENUM_MAP = {
  [yesterday.label]: TEAM_DURATIONS_OBJECT.yesterday,
  [lastWeek.label]: TEAM_DURATIONS_OBJECT.lastWeek,
  [lastTwoWeeks.label]: TEAM_DURATIONS_OBJECT.last2Weeks,
  [lastMonth.label]: TEAM_DURATIONS_OBJECT.lastMonth,
};

export const PULSE_SURVEY_CREATE_STATUS = {
  DRAFT: 'DRAFT',
  COMPLETED: 'PUBLISHED',
  ACTIVE: 'IN_PROGRESS',
};

/* Feedback Analytics */
export const MOST_LEAST_GROWTH_COMPETENCY_META = {
  MOST: {
    heading: 'Maximum Growth',
    subHeading: 'There is maximum growth in these competencies',
  },
  LEAST: {
    heading: 'Minimum Growth',
    subHeading: 'There is minimum growth in these competencies',
  },
};

export const HIGHEST_LOWEST_EMPLOYEE_STANDING_META = {
  MOST: {
    heading: 'Highest Rated',
    subHeading: 'These employees have highest comptency growth',
  },
  LEAST: {
    heading: 'Lowest Rated',
    subHeading: 'These employees have lowest comptency growth',
  },
};

export const PLUGIN_FEATURES_MAPPING = {
  JIRA_ISSUES: 'Issues Sync',
  JIRA_METRICS: 'Goal Tracking',
  APPLAUSE: 'Feedback',
  ONE_ON_ONE: '1:1',
  TASK: 'Tasks',
  GOAL: 'Goals',
};

/* Engagement Analytics */
export const HIGHEST_LOWEST_RATED_COHORT_META = {
  MOST: {
    heading: 'Most engaged cohort(s)',
    subHeading: 'These groups are the most engaged',
  },
  LEAST: {
    heading: 'Least engaged cohort(s)',
    subHeading: 'These groups are the least engaged',
  },
};

export const REVIEW_PROVIDER_TYPES = {
  SELF: 'SELF',
  MANAGER: 'MANAGER',
  PEER: 'PEER',
  UPWARD: 'UPWARD',
  DLM: 'DLM',
};

// MeshList consts
export const MESH_LIST_TABS = {
  task: {
    id: -1,
    taskType: TASK_TYPES_2.task,
    displayName: 'Initiatives',
  },
  goal: {
    id: -4,
    taskType: TASK_TYPES_2.goal,
    displayName: 'Goals',
  },
  // checkin should be renamed to oneOnOne
  checkIn: {
    id: -5,
    taskType: TASK_TYPES_2.checkIn,
    displayName: '1:1',
  },
  feedback: {
    id: -6,
    taskType: 'FEEDBACK',
    displayName: 'Feedback',
  },
  review: {
    id: -42, // this is random number and only used in local storage to match
    taskType: 'REVIEW',
    displayName: 'Reviews',
  },
  levelUp: {
    id: -44, // this is random number and only used in local storage to match
    taskType: 'LEVEL_UP',
    displayName: 'Competencies',
  },
  competencies: {
    id: -46, // this is random number and only used in local storage to match
    taskType: 'COMPETENCY',
    displayName: 'Competencies',
  },
};

export const PEER_NOMINATION_COHORTS = {
  JUNIORS: 'JUNIORS',
  SENIORS: 'SENIORS',
  PRIMARY_TEAM: 'PRIMARY_TEAM',
  OUTSIDE_TEAM: 'OUTSIDE_TEAM',
  ALL_ORG: 'ALL_ORG',
  DLM: 'DLM',
};

export const DEFAULT_ROWS_PER_PAGE = 25;

export const ANALYTICS_FILTERS_KEYS = {
  cohortId: 'cohortId',
  groupByKey: 'groupByKey',
  dateRange: 'dateRange',
  sourceUuid: 'sourceUuid',
  reviewViewType: 'reviewViewType',
  splitByKey: 'splitByKey',
  timeBounds: 'timeBounds',
};
export const GOAL_PROGRESS_ENTITIES = {
  ACHIEVEDLIST: 'achievedList',
  TARGETLIST: 'targetList',
};

export const MISCELLANEOUS_COMPETENCY_NAME = 'Miscellaneous';
export const MISCELLANEOUS_COMPETENCY_ID = 'MISC';
export const VIEW_ALL_COMPETENCY_ID = null;

export const ONEONONE_ANALYTICS_CHART_CONSTANTS = {
  activityGraph: {
    yAxisLabel: {
      employeeId: 'Number of team members',
      manager: 'Number of managers',
    },
    xAxisLabel: {
      employeeId: 'Percentage of completed 1:1s',
      manager: 'Percentage of completed 1:1s',
    },
  },
  mostAndLeastActiveChart: {
    employeeId: {
      MOST: {
        heading: 'Most Frequent 1:1s',
        subHeading: 'These are team members with most 1:1s every x days',
      },
      LEAST: {
        heading: 'Least Frequent 1:1s',
        subHeading: 'These are team members with least 1:1s every x days',
      },
    },
    manager: {
      MOST: {
        heading: 'Most Active Managers',
        subHeading: 'These are managers with most 1:1s every x days',
      },
      LEAST: {
        heading: 'Least Active Managers',
        subHeading: 'These are managers with least 1:1s every x days',
      },
    },
  },
};
export const RATINGS_TYPES = {
  OVERALL: 'Overall Performance Score',
  PERFORMANCE: 'Goal score',
  POTENTIAL: 'Competency score',
  QNA: 'Overall questions score',
};

export const ANALYTICS_EMAIL_SUBJECTS = {
  GOALS_TABLE: {
    emailSubject: 'Goal Progress',
  },
  ONEONONE_TABLE: {
    emailSubject: '1:1 meeting',
  },
  FEEDBACK_TABLE: {
    emailSubject: 'Competency Progress',
  },
};

export const CHARTS_PADDING = '0.75rem';
export const ENGAGEMENT_ANSWER_TYPES = {
  EMOJI: 'EMOJI',
  NUMBER_RATING: 'NUMBER_RATING',
  DROP_DOWN: 'DROP_DOWN',
  NONE: 'NONE',
};

export const NUDGE_CARD_TYPES = {
  REVIEW: {
    type: 'REVIEW',
    subTypes: {
      PENDING_SELF: 'PENDING_SELF',
      RESULT_PUBLISHED: 'RESULT_PUBLISHED',
      PENDING_NOMINATION: 'PENDING_NOMINATION',
    },
  },
  ONE_ON_ONE: {
    type: 'ONE_ON_ONE',
    subTypes: {},
  },
  ENGAGEMENT: {
    type: 'ENGAGEMENT',
    subTypes: {
      PENDING_SURVEY: 'PENDING_SURVEY',
    },
  },
  GROWTH: {
    type: 'GROWTH',
    subTypes: {
      APPLAUSE_GENDER_DISPARITY: 'APPLAUSE_GENDER_DISPARITY',
    },
  },
  SHORT_SURVEY: {
    type: 'SHORT_SURVEY',
    subTypes: {
      SHORT_SURVEY: 'SHORT_SURVEY',
    },
  },
  GOAL: {
    type: 'GOAL',
    subTypes: {
      GOAL_NOT_UPDATED: 'GOAL_NOT_UPDATED',
    },
  },
};

export const PULSE_SURVEY_STATUS = {
  SUBMITTED: 'SUBMITTED',
  NOT_STARTED: 'NOT_STARTED',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const ONE_ON_ONE_STATUSES = {
  NOT_STARTED: 'NOT_STARTED',
  CANCELLED: 'CANCELLED',
  ACTIVE: 'ACTIVE',
  DISABLED: 'DISABLED',
  ACKNOWLEDGED: 'ACKNOWLEDGED',
};

export const ONE_ON_ONE_INSTANCE_VIEWS = {
  INSTANCE_DETAILS: 'INSTANCE_DETAILS',
  RATING: 'RATING',
  SUBMISSION_MESSAGE: 'SUBMISSION_MESSAGE',
};

export const ONE_ON_ONE_TIMELINE_VIEWS = {
  TIMELINE_VIEW: 'TIMELINE_VIEW',
  RATING_VIEW: 'RATING_VIEW',
};

export const REVIEW_QUESTION_TYPES = {
  PERFORMANCE: 'PERFORMANCE',
  POTENTIAL: 'POTENTIAL',
  QNA: 'QNA',
};

export const QUESTION_ORDER_ACTIONS = {
  MOVE_UP: 'MOVE_UP',
  MOVE_DOWN: 'MOVE_DOWN',
};

export const REVIEW_QUESTION_TYPE_MAP_1 = {
  GOAL: 'PERFORMANCE',
  COMPETENCY: 'POTENTIAL',
  QNA: 'QNA',
};

export const REVIEW_QUESTION_TYPE_MAP_2 = {
  PERFORMANCE: 'GOAL',
  POTENTIAL: 'COMPETENCY',
  QNA: 'QNA',
  OVERALL: 'OVERALL',
};

export const REVIEW_QUESTION_TYPE_MAP_3 = {
  PERFORMANCE: 'PERFORMANCE',
  POTENTIAL: 'POTENTIAL',
  QNA: 'QNA',
  OVERALL: 'OVERALL',
  OUTCOME: 'OUTCOME',
};

export const CSV_DOWNLOAD_FILE_REQUEST_WAIT_INTERVAL = 10 * 60 * 1000;
export const CSV_DOWNLOAD_API_POLL_INTERVAL = 2500;

export const GENDER_CHART_COLORS = {
  MALE: '#B5D5F8',
  FEMALE: '#F6A9F8',
  OTHER: '#E8C061',
};

export const COMPETENCY_SIDEPANEL_TYPE = {
  COMPETENCY_DETAILED_PAGE: 'COMPETENCY_DETAILED_PAGE',
  HOME_PAGE: 'HOME_PAGE',
};

export const DEFAULT_CHECKIN_VISIBILITY = 'RECEIVER_AND_MANAGER';

export const CHECKIN_QUESTION_VISIBILITY = {
  manager: {
    value: 'MANAGER',
    label: 'Visible to Manager',
  },
  receiver: {
    value: 'RECEIVER',
    label: 'Visible to Receiver',
  },
  both: {
    value: 'RECEIVER_AND_MANAGER',
    label: 'Visible to Both',
  },
};

export const CHECKIN_QUESTION_TYPES = {
  EMOTION: 'EMOTION',
  TALKING_POINT: 'TALKING_POINT',
  MEETING_NOTE: 'MEETING_NOTE',
  AGENDA_ITEM: 'AGENDA_ITEM',
};

export const TEAM_REVIEW_TEXTS = {
  ERROR_MSG: 'No data to display',
};

export const OBJECTIVE_CREATION_PLACEHOLDER_TEXT = {
  LINE1: 'You can find all the objectives you have created in the past, plus',
  LINE2: 'you can also see and use some pre-populated sample objectives.',
};

export const OBJECTIVE_LEVEL_TAGS = [
  { value: 'COMPANY', label: 'Company', bgColor: '#50B7AE', icon: 'CTagIcon' },
  { value: 'DEPARTMENT', label: 'Department', bgColor: '#7074D5', icon: 'DTagIcon' },
  { value: 'TEAM', label: 'Team', bgColor: '#F2A043', icon: 'TTagIcon' },
  { value: 'INDIVIDUAL', label: 'Individual', bgColor: '#BB70D5', icon: 'ITagIcon' },
];

export const OBJECTIVE_LEVEL = {
  DEPARTMENT: 'DEPARTMENT',
  COMPANY: 'COMPANY',
  TEAM: 'TEAM',
  INDIVIDUAL: 'INDIVIDUAL',
};

export const ENGAGEMENT_DRIVERS_TAG_SUB_TYPES = {
  ENPS: { id: 'ENPS', displayName: 'eNPS', bgColor: '#50B7AE' },
  MNPS: { id: 'MNPS', displayName: 'mNPS', bgColor: '#BB70D5' },
  OVERALL_ENGAGEMENT: {
    id: 'OVERALL_ENGAGEMENT',
    displayName: 'Overall Engagement',
    bgColor: '#7074D5',
  },
  ENGAGEMENT_DRIVER: {
    id: 'ENGAGEMENT_DRIVER',
    displayName: 'Engagement Driver',
    bgColor: '#F2A043',
  },
};

export const ENGAGEMENT_DRIVERS_TAG_SUB_TYPES_OPTIONS = [
  {
    label: 'eNPS',
    value: 'ENPS',
    bgColor: '#50B7AE',
  },
  {
    label: 'mNPS',
    value: 'MNPS',
    bgColor: '#BB70D5',
  },
  {
    label: 'Overall Engagement',
    value: 'OVERALL_ENGAGEMENT',
    bgColor: '#7074D5',
  },
  {
    label: 'Engagement Driver',
    value: 'ENGAGEMENT_DRIVER',
    bgColor: '#F2A043',
  },
];

export const PROMO_STATUS_OPTIONS = [
  { value: 'PROMOTE', label: 'Promoted' },
  { value: 'NO_CHANGE', label: 'No Change' },
  { value: 'PIP', label: 'PIP' },
  { value: 'FUTURE_PROMOTE', label: 'Future Consideration' },
];

export const oneOnOneMoods = [
  {
    key: 5,
    label: 'Extremely happy',
  },
  {
    key: 4,
    label: 'Happy',
  },
  {
    key: 3,
    label: 'Average',
  },
  {
    key: 2,
    label: 'Sad',
  },
  {
    key: 1,
    label: 'Extremely sad',
  },
];
export const HASHTAG_MENTION_REGEX = '[\\w-*]*';
/*
   it's used to allow "*" to be included in string, by default only "-" & "_" are allowed from special characters in mentionplugin of draft.js. Regex is expected to be a string by draft.js @https://github.com/draft-js-plugins/draft-js-plugins/search?q=mentionRegExp
  */

export const MODULE_STATUS = [
  {
    value: 'DISABLED',
    displayName: 'Disabled',
  },
  {
    value: 'BASIC',
    displayName: 'Base',
  },
  {
    value: 'ADVANCED',
    displayName: 'Advanced',
  },
];

export const HELLO_BAR_PAGE_TYPE = {
  REVIEW: 'REVIEW',
  ENGAGEMENT: 'ENGAGEMENT',
};

export const HELLO_BAR_PAGE_TYPE_2 = {
  REVIEW: 'review',
  ENGAGEMENT: 'engagement',
};

export const ACKNOWLEDGEMENT_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  SUBMITTED: 'SUBMITTED',
};

export const WEB_APP_COOKIE_DOMAIN = '.mesh.ai';

// Engagement Template
export const ENGAGEMENT_TEMPLATE_MENU_OPTIONS = {
  active: ['DUPLICATE', 'ARCHIVE'],
  archived: ['DUPLICATE', 'UNARCHIVE'],
};

// for outlook add-in
export const OFFICE_JS_SCRIPT_URL = 'https://appsforoffice.microsoft.com/lib/1/hosted/office.js';

export const APP_SOURCE_CONTAINERS = {
  WEB_APP: 'WEB_APP',
  PLUGIN: 'PLUGIN',
};

export const PROD_APP_DOMAIN = 'app.mesh.ai';
export const PROD_LOGIN_DOMAIN = 'login.mesh.ai';
export const DEMO_APP_DOMAIN = 'public-demo.mesh.ai';
// export const PROD_APP_DOMAIN = 'localhost';
// export const PROD_LOGIN_DOMAIN = 'localhost';

export const GOAL_VISIBILITY_OPTIONS = [
  {
    label: ' Owners, Collaborators and their Managers',
    value: 'PRIVATE',
    icon: 'PrivateViewIcon',
  },
  { label: 'Public', value: 'GLOBAL', icon: 'PublicViewIcon' },
];

export const AGENDA_STATUSES = {
  completed: 'COMPLETED',
  active: 'ACTIVE',
};

export const AGENDA_TAG_LABEL_TYPES = {
  LABEL_TASK: { value: 'LABEL_TASK', displayName: 'Initiatives' },
  LABEL_GOAL: { value: 'LABEL_GOAL', displayName: 'Goals' },
  LABEL_LEVELUP: { value: 'LABEL_LEVELUP', displayName: 'Develop' },
  LABEL_FEEDBACK: { value: 'LABEL_FEEDBACK', displayName: 'Feedback' },
  LABEL_AGENDA: { value: 'LABEL_AGENDA', displayName: 'Agenda' },
};

export const TASK_EMPLOYEE_TYPES = { owner: 'OWNER', collaborator: 'COLLABORATOR' };

export const REFRESH_OBJECTIVE_TABLE_TEXT = 'Please refresh the table to see udpated data.';
export const MESH_RECOMMENDATIONS_TYPES = {
  REVIEW_PUBLISHED: 'REVIEW_PUBLISHED',
};

export const COHORT_TYPES_GOALS = {
  MY_OKRs: 'MY_OKRs',
  MY_TEAM: 'MY_TEAM',
  MY_ORGANIZATION: 'MY_ORGANIZATION',
  ALL_EMPLOYEE: 'ALL_EMPLOYEE',
  CUSTOM_TYPE: 'CUSTOM_TYPE',
  POD: 'POD',
  MY_DEPARTMENT: 'MY_DEPARTMENT',
};

export const OKR_TAG_TYPES = {
  OKR_TAG: 'OKR_TAG',
  SUB_OKR_TAG: 'SUB_OKR_TAG',
};

export const OBJECTIVE_ACCESS_PERMISSIONS = [
  { label: 'Only Admins can create', value: 'ADMIN' },
  { label: 'Both Admins and Managers can create', value: 'ADMIN_AND_MANAGER' },
];

export const VIEWS_FILTER_KEYS = {
  BASE_COHORT: 'base_cohort',
  GRADE: 'grade',
  ROLE: 'roleId',
  FUNCTION: 'functionId',
  DEPARTMENT: 'department',
  LOCATION: 'location',
  COUNTRY: 'country', // check if this exists
  GENDER: 'gender',
  ETHNICITY: 'ethnicity',
  GOALS: 'goals',
  ONE_ON_ONE: 'oneOnOne',
  REVIEW: 'review',
  ENGAGEMENT: 'engagement',
  FEEDBACK: 'feedback',
  MANAGERS: 'managers',
  EMPLOYEES: 'employees',
  CSV_EMPLOYEES: 'csvEmployees',
  SIDEBAR_VIEW_COHORTS: 'sidebarViewCohorts',
};

export const VIEWS_FILTER_KEYS_DISPLAY_NAMES = {
  [VIEWS_FILTER_KEYS.BASE_COHORT]: null,
  [VIEWS_FILTER_KEYS.GRADE]: 'Grade',
  [VIEWS_FILTER_KEYS.ROLE]: 'Role',
  [VIEWS_FILTER_KEYS.FUNCTION]: 'Function',
  [VIEWS_FILTER_KEYS.DEPARTMENT]: 'Department',
  [VIEWS_FILTER_KEYS.LOCATION]: 'Location',
  [VIEWS_FILTER_KEYS.COUNTRY]: 'Country', // check if this exists
  [VIEWS_FILTER_KEYS.GENDER]: 'Gender',
  [VIEWS_FILTER_KEYS.ETHNICITY]: 'Ethnicity',
  [VIEWS_FILTER_KEYS.GOALS]: 'Goals',
  [VIEWS_FILTER_KEYS.ONE_ON_ONE]: '1:1',
  [VIEWS_FILTER_KEYS.REVIEW]: 'Review',
  [VIEWS_FILTER_KEYS.ENGAGEMENT]: 'Engagement',
  [VIEWS_FILTER_KEYS.FEEDBACK]: 'Feedback',
  [VIEWS_FILTER_KEYS.MANAGERS]: 'Manager',
  [VIEWS_FILTER_KEYS.EMPLOYEES]: 'Name',
};

export const VIEWS_VISIBILITY_TYPES_LABELS = {
  PRIVATE: 'Private',
  SHARED: 'Shared',
};
export const VIEWS_VISIBILITY_TYPES_VALUES = {
  PRIVATE: 'PRIVATE',
  SHARED: 'ADMIN',
};

export const VIEWS_DYNAMIC_TYPES_LABELS = {
  DYNAMIC: 'Dynamic',
  STATIC: 'Static',
};
export const VIEWS_DYNAMIC_TYPES_VALUES = {
  DYNAMIC: 'DYNAMIC',
  STATIC: 'STATIC',
};

export const MAX_OKR_NAME_LENGTH = 500;
export const MIN_OKR_NAME_LENGTH = 1;
export const MAX_OKR_DESCRIPTION_LENGTH = 5000;
export const MIN_OKR_DESCRIPTION_LENGTH = 0;
export const MAX_OKR_METRIC_LENGTH = 25;
export const MIN_OKR_METRIC_LENGTH = 0;

export const OKR_PROGRESS_TYPES = {
  MANUAL: 'MANUAL',
  DERIVED_SUM: 'DERIVED_SUM',
  DERIVED_AVG: 'DERIVED_AVG',
  TRACKED: 'TRACKED',
};

export const OKR_TYPES = {
  KEY_RESULT: 'GOAL',
  OBJECTIVE: 'OBJECTIVE',
};

export const OBJECTIVE_TYPES_MAP = {
  SIMPLE: { label: 'Simple', value: 'SIMPLE' },
  COMPACT: { label: 'Compact', value: 'COMPACT' },
  DETAILED: { label: 'Detailed', value: 'DETAILED' },
  DISABLED: { label: 'Disabled', value: 'DISABLED' },
};
export const OBJECTIVE_TYPES_LIST = [
  OBJECTIVE_TYPES_MAP.SIMPLE,
  OBJECTIVE_TYPES_MAP.COMPACT,
  OBJECTIVE_TYPES_MAP.DETAILED,
  OBJECTIVE_TYPES_MAP.DISABLED,
];

export const OBJECTIVE_VISIBILITY_TYPES_MAP = {
  PUBLIC: { label: 'Public', value: 'PUBLIC' },
  PRIVATE: { label: 'Private', value: 'PRIVATE' },
};

export const OBJECTIVE_VISIBILITY_TYPES_LIST = [
  OBJECTIVE_VISIBILITY_TYPES_MAP.PUBLIC,
  OBJECTIVE_VISIBILITY_TYPES_MAP.PRIVATE,
];

export const OBJECTIVE_PROGRESS_TYPES_MAP = {
  DERIVED: { label: 'Derived', value: 'DERIVED' },
  MANUAL: { label: 'Manual', value: 'MANUAL' },
  TRACKED: { label: 'Tracked', value: 'TRACKED' },
};

export const OBJECTIVE_PROGRESS_TYPES_LIST = [
  OBJECTIVE_PROGRESS_TYPES_MAP.DERIVED,
  OBJECTIVE_PROGRESS_TYPES_MAP.MANUAL,
  OBJECTIVE_PROGRESS_TYPES_MAP.TRACKED,
];

/* Privileges enums */

export const CREATE_OBJECTIVE_PRIVILEGES = {
  CREATE_OBJECTIVE_INDIVIDUAL: 'CREATE_OBJECTIVE_INDIVIDUAL',
  CREATE_OBJECTIVE_TEAM: 'CREATE_OBJECTIVE_TEAM',
  CREATE_OBJECTIVE_DEPARTMENT: 'CREATE_OBJECTIVE_DEPARTMENT',
  CREATE_OBJECTIVE_COMPANY: 'CREATE_OBJECTIVE_COMPANY',
};
export const CREATE_KEY_RESULT_PRIVILEGES = {
  CREATE_KEY_RESULT_PARENT_MANDATORY: 'CREATE_KEY_RESULT_PARENT_MANDATORY',
  CREATE_KEY_RESULT_PARENT_NON_MANDATORY: 'CREATE_KEY_RESULT_PARENT_NON_MANDATORY',
};

/* Insights View and Download Privileges */
export const VIEW_DOWNLOAD_INSIGHT_PRIVILEGES = {
  VIEW_INDIVIDUAL_INSIGHTS: 'VIEW_INDIVIDUAL_INSIGHTS',
  DOWNLOAD_INDIVIDUAL_INSIGHTS: 'DOWNLOAD_INDIVIDUAL_INSIGHTS',
  VIEW_OBJECTIVE_LEVEL_INSIGHTS: 'VIEW_OBJECTIVE_LEVEL_INSIGHTS',
  DOWNLOAD_OBJECTIVE_LEVEL_INSIGHTS: 'DOWNLOAD_OBJECTIVE_LEVEL_INSIGHTS',
};

/* Bulk Upload Privileges */
export const BULK_UPLOAD_PRIVILEGES = {
  BULK_UPLOAD_OBJECTIVES: 'BULK_UPLOAD_OBJECTIVES',
  BULK_UPLOAD_KEY_RESULTS: 'BULK_UPLOAD_KEY_RESULTS',
  BULK_UPLOAD_INITIATIVES: 'BULK_UPLOAD_INITIATIVES',
};

export const KR_VISIBILITY_TYPES_MAP = OBJECTIVE_VISIBILITY_TYPES_MAP;
export const KR_VISIBILITY_TYPES_LIST = OBJECTIVE_VISIBILITY_TYPES_LIST;
export const KR_PROGRESS_TYPES_MAP = OBJECTIVE_PROGRESS_TYPES_MAP;
export const KR_PROGRESS_TYPES_LIST = OBJECTIVE_PROGRESS_TYPES_LIST;

export const TOAST_STATUSES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};

export const GRID_COLORS_MAP = {
  blue: { text: 'functional.1565BC', bg: 'feedback.D6E7F9' },
  grey: {
    text: 'neutral.666666',
    bg: 'neutral.EBEFF3',
  },
  green: {
    text: 'functional.1A5724',
    bg: 'feedback.BFE8C6',
  },

  orange: {
    text: 'functional.AF5C20',
    bg: 'functional.FFE7C5',
  },

  red: {
    text: 'functional.C62C02',
    bg: 'feedback.FFE1D9',
  },
};

export const GRID_COLORS_LABELS = ['Green', 'Red', 'Blue', 'Orange', 'Grey'];

export const GRID_COLORS_KEYS = ['green', 'red', 'blue', 'orange', 'grey'];

export const DEMO_DEFAULT_GRID_COLORS = [
  ['grey', 'green', 'blue', 'red'],
  ['red', 'red', 'green', 'blue'],
  ['red', 'orange', 'orange', 'grey'],
  ['green', 'red', 'orange', 'grey'],
];

export const DEMO_DEFAULT_PERFORMANCE_LABELS = [
  ['Potential Gem', 'High Potential', 'High Potential', 'Star'],
  ['Potential Gem', 'Potential Gem', 'High Potential', 'Star'],
  ['Inconsistent Player', 'Core Player', 'Potential Gem', 'High Potential'],
  ['Risk', 'Average', 'Average', 'Solid Performer'],
];
export const CREATE_EDIT_DELETE_CUSTOM_TAGS = 'CREATE_EDIT_DELETE_CUSTOM_TAGS';
export const VIEW_ASSIGN_CUSTOM_TAGS = 'VIEW_ASSIGN_CUSTOM_TAGS';
export const MODULE_KEYS_USER_PREFERENCE_PERSISTENCE = MODULE_KEYS;

export const INPROGRESS_STATUS_COLOR_MAP = {
  ON_TRACK: {
    barColor: '#B1E39F',
    textColor: themeColors.functional['44812F'],
    tagColor: themeColors.feedback.EFFFEA,
  },
  OFF_TRACK: {
    barColor: '#FCC88B',
    textColor: themeColors.functional['8C6A00'],
    tagColor: themeColors.feedback.FFF6D3,
  },
  AT_RISK: {
    barColor: '#FD9B9B',
    textColor: themeColors.functional.C62C02,
    tagColor: themeColors.feedback.FFF1E4,
  },
  // for case where closed sub status is undefined
  CLOSED: {
    barColor: themeColors.neutral.CCCCCC,
    textColor: themeColors.secondary['666666'],
    tagColor: themeColors.neutral.CCCCCC,
  },
};

export const CLOSED_STATUS_COLOR_MAP = {
  ACHIEVED: {
    textColor: themeColors.functional['1A5724'],
    tagColor: themeColors.feedback.DBF2D3,
  },
  NOT_ACHIEVED: {
    textColor: themeColors.functional.A12402,
    tagColor: themeColors.feedback.FFE1D9,
  },
  DROPPED: {
    textColor: themeColors.secondary['666666'],
    tagColor: themeColors.neutral.CCCCCC,
  },
};

export const getGoalStatusColorObject = (status, goalClosureStatus) =>
  status === 'CLOSED' && goalClosureStatus
    ? CLOSED_STATUS_COLOR_MAP[goalClosureStatus]
    : INPROGRESS_STATUS_COLOR_MAP[status];

export const COLOR_MAP_FULL = {
  ...INPROGRESS_STATUS_COLOR_MAP,
  CLOSED_ACHIEVED: { ...CLOSED_STATUS_COLOR_MAP.ACHIEVED },
  CLOSED_NOT_ACHIEVED: { ...CLOSED_STATUS_COLOR_MAP.NOT_ACHIEVED },
  CLOSED_DROPPED: { ...CLOSED_STATUS_COLOR_MAP.DROPPED },
};

export const getGoalStatusColor = (status, goalClosureStatus) => {
  const progressColors = getGoalStatusColorObject(status, goalClosureStatus);
  return progressColors?.textColor ?? theme.palette.primary.main;
};

export const GOAL_PROGRESSES_PARTIAL_LABEL = {
  ON_TRACK: 'On-track',
  OFF_TRACK: 'Off-track',
  AT_RISK: 'At-risk',
  CLOSED: 'Closed',
};

export const GOAL_PROGRESSES_FULL_LABEL = {
  ON_TRACK: 'On-track',
  OFF_TRACK: 'Off-track',
  AT_RISK: 'At-risk',
  CLOSED: 'Closed',
  CLOSED_ACHIEVED: 'Closed & achieved',
  CLOSED_DROPPED: 'Closed & dropped',
  CLOSED_NOT_ACHIEVED: 'Closed & not achieved',
};

export const GOAL_PROGRESS_STATUS_OBJECTS = {
  onTrack: {
    value: 'ON_TRACK',
    label: 'On-Track',
    color: COLOR_MAP_FULL.ON_TRACK.textColor,
    textColor: COLOR_MAP_FULL.ON_TRACK.textColor,
    tagColor: COLOR_MAP_FULL.ON_TRACK.tagColor,
    progressLevel: 'ON_TRACK',
    order: 0,
  },
  offTrack: {
    value: 'OFF_TRACK',
    label: 'Off-Track',
    color: COLOR_MAP_FULL.OFF_TRACK.textColor,
    textColor: COLOR_MAP_FULL.OFF_TRACK.textColor,
    tagColor: COLOR_MAP_FULL.OFF_TRACK.tagColor,
    progressLevel: 'OFF_TRACK',
    order: 1,
  },
  atRisk: {
    value: 'AT_RISK',
    label: 'At-Risk',
    color: COLOR_MAP_FULL.AT_RISK.textColor,
    textColor: COLOR_MAP_FULL.AT_RISK.textColor,
    tagColor: COLOR_MAP_FULL.AT_RISK.tagColor,
    progressLevel: 'AT_RISK',
    order: 2,
  },
  closed: {
    value: 'CLOSED',
    label: 'Closed',
    color: COLOR_MAP_FULL.CLOSED.textColor,
    textColor: COLOR_MAP_FULL.CLOSED.textColor,
    tagColor: COLOR_MAP_FULL.CLOSED.tagColor,
    progressLevel: 'CLOSED',
    order: 3,
  },
  closedAchieved: {
    value: 'ACHIEVED',
    label: 'Closed - Achieved',
    shortLabel: 'Closed(Ach)',
    color: COLOR_MAP_FULL.CLOSED_ACHIEVED.textColor,
    textColor: COLOR_MAP_FULL.CLOSED_ACHIEVED.textColor,
    tagColor: COLOR_MAP_FULL.CLOSED_ACHIEVED.tagColor,
    progressLevel: 'CLOSED',
  },
  closedNonAchieved: {
    value: 'NOT_ACHIEVED',
    label: 'Closed - Not Achieved',
    shortLabel: 'Closed(NAch)',
    color: COLOR_MAP_FULL.CLOSED_NOT_ACHIEVED.textColor,
    textColor: COLOR_MAP_FULL.CLOSED_NOT_ACHIEVED.textColor,
    tagColor: COLOR_MAP_FULL.CLOSED_NOT_ACHIEVED.tagColor,
    progressLevel: 'CLOSED',
  },
  closedDropped: {
    value: 'DROPPED',
    label: 'Closed - Dropped',
    shortLabel: 'Closed(Drop)',
    color: COLOR_MAP_FULL.CLOSED_DROPPED.textColor,
    textColor: COLOR_MAP_FULL.CLOSED_DROPPED.textColor,
    tagColor: COLOR_MAP_FULL.CLOSED_DROPPED.tagColor,
    progressLevel: 'CLOSED',
  },
};
export const GOAL_IN_PROGRESS_OPTIONS = [
  GOAL_PROGRESS_STATUS_OBJECTS.onTrack,
  GOAL_PROGRESS_STATUS_OBJECTS.offTrack,
  GOAL_PROGRESS_STATUS_OBJECTS.atRisk,
];

export const GOAL_CLOSED_OPTIONS = [
  // 'ACHIEVED','NOT_ACHIEVED','DROPPED'
  GOAL_PROGRESS_STATUS_OBJECTS.closedAchieved,
  GOAL_PROGRESS_STATUS_OBJECTS.closedNonAchieved,
  GOAL_PROGRESS_STATUS_OBJECTS.closedDropped,
];

export const GOAL_PROGRESS_OPTIONS = [
  GOAL_PROGRESS_STATUS_OBJECTS.onTrack,
  GOAL_PROGRESS_STATUS_OBJECTS.offTrack,
  GOAL_PROGRESS_STATUS_OBJECTS.atRisk,
  GOAL_PROGRESS_STATUS_OBJECTS.closed,
  ...GOAL_CLOSED_OPTIONS,
];

export const GOAL_PROGRESS_OPTIONS_MAP = GOAL_PROGRESS_OPTIONS.reduce(
  (prevValue, currentValue) => ({
    ...prevValue,
    [currentValue.value]: {
      label: currentValue.label,
      color: currentValue.color,
      textColor: currentValue.textColor,
      tagColor: currentValue.tagColor,
      order: currentValue.order,
    },
  }),
  {}
);
export const FINCH_CLIENT_ID = '283a0567-b748-4bf8-9e83-607ba9683c36';
export const FINCH_CLIENT_ID_PROD = '9009206c-008c-40e6-9862-293ca001153d';

export const COHORT_TYPES_ONE_ON_ONE = {
  MY_ONE_ON_ONE: 'MY_ONE_ON_ONE',
  MY_COMPANY: 'ALL_EMPLOYEE',
  MY_ORGANIZATION_TREE: 'MY_ORGANIZATION',
};
export const COHORT_TYPES_ENGAGEMENT = {
  MY_COMPANY: 'ALL_EMPLOYEE',
  MY_ORGANIZATION_TREE: 'MY_ORGANIZATION',
  MY_TEAM: 'MY_TEAM',
};

export const COHORT_TYPES_REVIEW = {
  MY_REVIEWS: 'MY_REVIEWS',
  MY_COMPANY: 'ALL_EMPLOYEE',
  MY_ORGANIZATION_TREE: 'MY_ORGANIZATION',
  MY_TEAM: 'MY_TEAM',
};

export const VISIER_NAME_MAPPING = {
  ANALYZING_GOAL_ACHIEVEMENT: 'Analyzing Goal Achievement',
  ANALYZING_PERFORMANCE: 'Analyzing Performance',
  ANALYZING_ENGAGEMENT: 'Analyzing Engagement',
  ANALYZING_MANAGER_1_1S: 'Analyzing Manager 1:1s',
  ANALYZING_COMPETENCIES: 'Analyzing Competencies',
};

export const COMPANY_VISIER_ANALYSIS = [
  {
    id: '7349cda4-70c2-41cc-b359-024e1da3a0ee',
    name: VISIER_NAME_MAPPING.ANALYZING_GOAL_ACHIEVEMENT,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/7349cda4-70c2-41cc-b359-024e1da3a0ee',
  },
  {
    id: 'cb01b3fb-b5f5-40f2-98d2-6d4c9ae61607',
    name: VISIER_NAME_MAPPING.ANALYZING_PERFORMANCE,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/cb01b3fb-b5f5-40f2-98d2-6d4c9ae61607',
  },
  {
    id: '331305bd-726c-483c-9193-dd9c895b9e34',
    name: VISIER_NAME_MAPPING.ANALYZING_ENGAGEMENT,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/331305bd-726c-483c-9193-dd9c895b9e34',
  },
  {
    id: '833e877a-e79d-4ad0-b958-21ce3619c8cb',
    name: VISIER_NAME_MAPPING.ANALYZING_MANAGER_1_1S,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/833e877a-e79d-4ad0-b958-21ce3619c8cb',
  },
  {
    id: '6c502d38-1af3-4ec9-817f-385af8e1c7e9',
    name: VISIER_NAME_MAPPING.ANALYZING_COMPETENCIES,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/6c502d38-1af3-4ec9-817f-385af8e1c7e9',
  },
];

export const TEAM_DIRECT_VISIER_ANALYSIS = [
  {
    id: '1a33480d-0784-48e8-b174-c766cfcc5fab',
    name: VISIER_NAME_MAPPING.ANALYZING_GOAL_ACHIEVEMENT,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/7349cda4-70c2-41cc-b359-024e1da3a0ee?contextFilters={"filters":[{"concept":{"conceptId":"isMyDirectReports","subjectId":"Employee"}}]}',
  },
  {
    id: 'ba3cbf84-f1bd-4e29-a09f-4c653767f434',
    name: VISIER_NAME_MAPPING.ANALYZING_PERFORMANCE,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/cb01b3fb-b5f5-40f2-98d2-6d4c9ae61607?contextFilters={"filters":[{"concept":{"conceptId":"isMyDirectReports","subjectId":"Employee"}}]}',
  },
  {
    id: '83d86276-20a2-4e9f-b020-2de9447c9496',
    name: VISIER_NAME_MAPPING.ANALYZING_MANAGER_1_1S,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/833e877a-e79d-4ad0-b958-21ce3619c8cb?contextFilters={"filters":[{"concept":{"conceptId":"isMyDirectReports","subjectId":"Employee"}}]}',
  },
  {
    id: '14ffae65-f4da-4727-99d3-21dbecc70aa7',
    name: VISIER_NAME_MAPPING.ANALYZING_COMPETENCIES,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/6c502d38-1af3-4ec9-817f-385af8e1c7e9?contextFilters={"filters":[{"concept":{"conceptId":"isMyDirectReports","subjectId":"Employee"}}]}',
  },
];

export const TEAM_SKIP_VISIER_ANALYSIS = [
  {
    id: 'd223711b-ca26-4857-b4de-6cd3fb422ba3',
    name: VISIER_NAME_MAPPING.ANALYZING_GOAL_ACHIEVEMENT,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/7349cda4-70c2-41cc-b359-024e1da3a0ee?contextFilters={"filters":[{"concept":{"conceptId":"isMyReports","subjectId":"Employee"}}]}',
  },
  {
    id: '9a0b8cd5-7a27-4e25-b8ba-06c4a9d539e1',
    name: VISIER_NAME_MAPPING.ANALYZING_PERFORMANCE,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/cb01b3fb-b5f5-40f2-98d2-6d4c9ae61607?contextFilters={"filters":[{"concept":{"conceptId":"isMyReports","subjectId":"Employee"}}]}',
  },
  {
    id: '53c964e8-d712-47a6-afeb-a04bdd08a550',
    name: VISIER_NAME_MAPPING.ANALYZING_MANAGER_1_1S,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/833e877a-e79d-4ad0-b958-21ce3619c8cb?contextFilters={"filters":[{"concept":{"conceptId":"isMyReports","subjectId":"Employee"}}]}',
  },
  {
    id: 'b7e017e5-f277-4d38-9950-61752f8ecbb9',
    name: VISIER_NAME_MAPPING.ANALYZING_COMPETENCIES,
    url: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/6c502d38-1af3-4ec9-817f-385af8e1c7e9?contextFilters={"filters":[{"concept":{"conceptId":"isMyReports","subjectId":"Employee"}}]}',
  },
];

export const VISIER_DATA_EXPORT_ROOMS = {
  reviews:
    'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/dataconnector/068e878f-6db5-4b91-b1d8-7a46f0c6a6f1?mode=stand-alone',
  goals: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/dataconnector',
  oneOnOne:
    'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/dataconnector/01d62375-9f08-4b7b-9400-7b5dccacc7ab?mode=stand-alone',
  reports: 'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/dataconnector',
  outcomesDashboard:
    'https://prod-ap00.app.visier.com/hr/prod/appcontainer#/analytics/myanalyses/dfeb71a4-fc0d-4ac2-a360-b88577743ebb',
};

export const COHORT_TYPES_FEEDBACK = {
  MY_FEEDBACK: 'MY_FEEDBACK',
  MY_COMPANY: 'ALL_EMPLOYEE',
  MY_ORGANIZATION_TREE: 'MY_ORGANIZATION',
  MY_TEAM: 'MY_TEAM',
};

export const UPLOAD_GOAL_HELP_URL = 'https://help.mesh.ai/hc/en-us/articles/20545366607645-Creating-goals-via-upload';
